// auth-service.js
import gatedLogo from '/gated-logo2.png';
import { UserSession, AppConfig } from '@stacks/auth';
import { showConnect } from '@stacks/connect';

export class AuthService {
    constructor(router) {
        this.router = router;
        this.initialized = false;
        this.currentAddress = null;

        // Initialize app config with required permissions
        const appConfig = new AppConfig(
            ['store_write'], // permissions
            window.location.origin, // your app domain
            '/manifest.json', // path to manifest
            '/auth' // redirect URI for auth
        );

        // Initialize user session
        this.userSession = new UserSession({ appConfig });

        // Initialize immediately
        this.initialize().then(() => {
            console.log('Auth service fully initialized');
            this.initialized = true;

            // Automatically check auth state and redirect if needed
            this.checkStoredAuthState()
        });
    }

    // Update the onFinish callback in connectWallet

    // Add methods for managing auth state
    checkStoredAuthState() {
        if (this.userSession.isUserSignedIn()) {
            const userData = this.userSession.loadUserData();
            this.currentAddress = userData.profile.stxAddress.mainnet;
            localStorage.setItem('authAddress', this.currentAddress);
            localStorage.setItem('isAuthenticated', 'true');
            console.log('LocalStorage set:', {
                authAddress: localStorage.getItem('authAddress'),
                isAuth: localStorage.getItem('isAuthenticated')
            });
            return true;
        } else {
            this.clearStoredAuthState();
            return false;
        }
    }

    async initialize() {
        console.log('Initializing auth service');

        if (this.userSession.isSignInPending()) {
            console.log('Handling pending sign in');
            await this.handlePendingSignIn();
        } else if (this.userSession.isUserSignedIn()) {
            console.log('User is already signed in');
            const userData = this.userSession.loadUserData();
            this.updateUserState(userData);
            // Store auth state
            this.storeAuthState(userData);
        } else {
            // Check if we have stored auth state
            this.checkStoredAuthState();
        }

        console.log('Auth state after initialization:', {
            isSignedIn: this.isSignedIn(),
            currentAddress: this.getCurrentAddress(),
            storedAuth: localStorage.getItem('isAuthenticated'),
            storedAddress: localStorage.getItem('authAddress')
        });
    }

    storeAuthState(userData) {
        if (userData?.profile?.stxAddress?.mainnet) {
            localStorage.setItem('authAddress', userData.profile.stxAddress.mainnet);
            localStorage.setItem('isAuthenticated', 'true');
        }
    }

    clearStoredAuthState() {
        localStorage.removeItem('authAddress');
        localStorage.removeItem('isAuthenticated');
    }

    updateUserState(userData) {
        if (userData?.profile?.stxAddress?.mainnet) {
            this.storeAuthState(userData);
        }
    }

    isSignedIn() {
        return this.userSession.isUserSignedIn() && localStorage.getItem('isAuthenticated') === 'true';
    }

    getCurrentAddress() {
        if (this.userSession.isUserSignedIn()) {
            return this.userSession.loadUserData()?.profile?.stxAddress?.mainnet ||
                localStorage.getItem('authAddress');
        }
        return null;
    }

    async handlePendingSignIn() {
        try {
            const userData = await this.userSession.handlePendingSignIn();
            this.updateUserState(userData);
            return userData;
        } catch (error) {
            console.error('Error handling pending sign in:', error);
            this.clearStoredAuthState();
            throw error;
        }
    }

    // Do NOT add "onFinish" as a standalone method in your class
    // Instead, modify your existing connectWallet method like this:

    async connectWallet(isChange = false) {

        console.log('Starting wallet connection. isChange:', isChange);


        return new Promise((resolve, reject) => {
            console.log('Showing connect modal');

            let timeoutId;
            let isResolved = false;

            const cleanup = () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = null;
                }
            };

            // Set timeout for connection attempt
            timeoutId = setTimeout(() => {
                if (!isResolved) {
                    console.log('Connection attempt timed out');
                    cleanup();
                    reject(new Error('Connection timed out'));
                }
            }, 30000); // 30 second timeout


            showConnect({
                appDetails: {
                    name: 'GATED',
                    icon: gatedLogo,
                },
                redirectTo: '/auth',  // Remove window.location.origin
                onCancel: () => {
                    console.log('User cancelled connection');
                    cleanup();
                    if (!isResolved) {
                        reject(new Error('User cancelled connection'));
                    }
                },
                onFinish: async () => {
                    console.log('Connection finished callback triggered');
                    try {
                        await new Promise(resolve => setTimeout(resolve, 500));

                        if (!this.userSession.isUserSignedIn()) {
                            throw new Error('Session not established after connection');
                        }
                        const userData = this.userSession.loadUserData();
                        console.log('User data loaded:', userData);

                        const currentAddress = userData.profile.stxAddress;
                        console.log('User state updated with address:', currentAddress);

                        // Force a route update here
                        const parsedUrl = this.router.parseUrl();
                        console.log('Current parsed URL:', parsedUrl);

                        // If we're on a subdomain that matches the user's address, go to dashboard
                        if (parsedUrl.stxAddress === currentAddress) {
                            console.log('User on their subdomain, routing to dashboard');
                            await this.router.renderDashboard();
                        } else {
                            // If not on user's subdomain, trigger general route handling
                            await this.router.handleRoute();
                        }

                        isResolved = true;
                        cleanup();
                        resolve(currentAddress);
                    } catch (error) {
                        console.error('Error in auth completion:', error);
                        cleanup();
                        reject(error);
                    }
                },
                userSession: this.userSession,
                appConfig: {
                    manifestPath: '/manifest.json',
                    redirectPath: '/auth',
                    cookieOptions: {
                        domain: window.location.hostname.includes('localhost')
                            ? 'localhost'
                            : '.gated.so',
                    }
                }
            });
        });
            // Then add the redirectToUserSubdomain method as a separate method in your class
           
    };

    redirectToUserSubdomain() {
        // Get the user's address from all possible sources
        const userAddress = this.currentAddress ||
            localStorage.getItem('authAddress') ||
            this.userSession?.loadUserData()?.profile?.stxAddress?.mainnet;

        if (!userAddress) {
            console.error('Cannot redirect: No user address found');
            return;
        }

        console.log('Redirecting to user subdomain for:', userAddress);

        // Get current domain information
        const currentHostname = window.location.hostname;
        const protocol = window.location.protocol;

        // Check if we're already on the user's subdomain
        const isUserSubdomain = currentHostname.startsWith(userAddress.toLowerCase());
        if (isUserSubdomain) {
            console.log('Already on user subdomain, refreshing page');
            window.location.reload();
            return;
        }

        // Determine if we're on localhost or production
        const isLocalhost = currentHostname.includes('localhost');
        const baseDomain = isLocalhost ? 'localhost:3000' : 'gated.so';

        // Construct the subdomain URL
        const targetUrl = `${protocol}//${userAddress}.${baseDomain}`;

        console.log('Navigating to user subdomain:', targetUrl);
        window.location.href = targetUrl;
    };

    disconnectWallet() {
        if (this.isSignedIn()) {
            this.userSession.signUserOut();
            this.router.setCurrentStxAddress(null);

            // If on subdomain, redirect to main domain
            const host = window.location.hostname;
            const isSubdomain = host.includes('.');

            if (isSubdomain) {
                const domain = host.includes('gated.so') ? 'gated.so' : 'localhost:3000';
                window.location.href = `${window.location.protocol}//${domain}`;
            } else {
                // If already on main domain, just refresh the page
                window.location.reload();
            }
        }
    }

    formatAccountNumber(address) {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }

    // Method to handle authentication state after signing in
    handleAuthentication() {
        if (this.userSession.isUserSignedIn()) {
            const userData = this.userSession.loadUserData();
            this.currentAddress = userData.profile.stxAddress.mainnet;
            localStorage.setItem('authAddress', this.currentAddress);
            localStorage.setItem('isAuthenticated', 'true');
            return true;
        }
        return false;
    }

}
import { AuthService } from '/auth-service.js';
import { UserSession } from '@stacks/auth';
import { AppConfig, showConnect, openContractCall } from '@stacks/connect';
import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';
import { bufferCV, boolCV, addressToString, Cl, deserializePrincipal, standardPrincipalCVFromAddress, intCV, uintCV, PostConditionMode, stringAsciiCV, principalCV, standardPrincipalCV, FungibleConditionCode, makeContractCall, broadcastTransaction, fetchCallReadOnlyFunction, READONLY_FUNCTION_CALL_PATH, cvToJSON, Pc } from '@stacks/transactions';
import WalletDropdown from './walletDropdown.js';
import gatedLogo from '/gated-logo2.png';
import blocktuneLogo from '/blocktune-logo.jpg';
import { NFTAudioIntegration } from './nftAvailability.js';
import loader from './loader.js';
import './nft-swap.js';
import { handlePageUpdate } from './pageConfig.js';
import { initNFTFallEffect } from './snowEffect.js';
import { EditSidebar } from './editSidebar.js';
import { MochineNFTManager } from './mochineNFTContracts.js';
import { initNFTInteractions } from './mochineNFTContracts.js';
import { MOCHINE_CONTRACTS } from './mochineNFTContracts.js';
import { initializeDarkModeToggle } from './darkMode.js';
import roo from '/roo.png';
import { musicData, musicPlayer, albumMetadata } from './musicConfig.js';
import { ProfilePhoto } from './script.js';
import { renderStoreItems, fetchUserNFTs, handleMint, createMusicPlayerCard, togglePlayPause, loadTrack, playPreviousTrack, playNextTrack } from './script.js';  // Add this import
import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Track 1_ Josie Field - Radio Silence.wav';
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Track 2_ Ard Matthews - You Make it Alright.wav';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Track 3_ Arno Carstens - Mortals.wav';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Track 4_ Kahn Morbee - Buzzhead.wav';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Track 5_ Laurie Levine - Grey.wav';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from '/audio/Track 6_ Jacques Moolman - Exile.wav';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Track 7_ Evert Snyman - I Shouldnt Break.wav';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Track 8_ Gazelle - BBQ to Braai.wav';
import albumImage from '/audio/Album_image.png';
import mochineBanner from '/mochines/Banner.png'


window.app = {
  showCreatePageModal: null,  // Initialize as null
  hideCreatePageModal: null   // Initialize as null
};

function sanitizeUrl(title) {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/%20/g, '-') // Replace %20 with hyphens
    .replace(/[^a-z0-9-]/g, '') // Remove special characters
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .replace(/^-|-$/g, ''); // Remove leading/trailing hyphens

}

function createSettingsMenu(page) {
  return `
    <div class="settings-menu hidden" data-page-id="${page.id}">
    </div>
  `;
}


export class Router {

  constructor() {
    this.routes = [];
    this.currentStxAddress = null;
    this.mainContent = document.getElementById('main-content');
    if (!this.mainContent) {
      this.mainContent = document.createElement('div');
      this.mainContent.id = 'main-content';
      document.body.appendChild(this.mainContent);
    }

    this.sanitizeUrl = (str) => str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    this.owner = window.stxAddress;
    this.pendingUpdates = new Set();
    this.currentEffect = null;

    // Bind the methods to this instance
    window.app.showCreatePageModal = () => this.showCreatePageModal();
    window.app.hideCreatePageModal = () => this.hideCreatePageModal();

    try {
      // Initialize auth first
      console.log('1. Creating AuthService');

      this.auth = new AuthService(this);
      console.log('2. Get STX address', this.auth.currentAddress)
      // Initialize everything else only after auth is ready 
      this.initializeAfterAuth();
    } catch (error) {
      console.error('Failed to create AuthService:', error);
    }
  }

  async initializeAfterAuth() {


    initNFTInteractions(this.auth);

    console.log('Checking existing session...');
    this.checkExistingSession();

    // Initialize route handlers immediately
    this.initializeRouteHandlers();

    // Initialize music player
    this.initializeMusicPlayer();

    // Set up route change listeners
    this.setupEventListeners();

    // Set up wallet functions
    this.setupWalletFunctions();

    // Wait for DOM to be fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }

    console.log('Router initialization completed');
  }


  initNFTInteractions() {
    const nftManager = new MochineNFTManager(authService);
    const nfts = document.querySelectorAll('.nft-preview');

    // Add styles for availability indicators
    const style = document.createElement('style');
    style.textContent = `
    .nft-availability {
      margin: 10px 0;
      padding: 8px 12px;
      border-radius: 4px;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }
    .nft-availability.available {
      background-color: #ecfdf5;
      color: #047857;
      border: 1px solid #047857;
    }
    .nft-availability.sold-out {
      background-color: #fef2f2;
      color: #dc2626;
      border: 1px solid #dc2626;
    }
    .mint-button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: #d1d5db;
    }
  `;
    document.head.appendChild(style);

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeFullscreen();
      }
    });

    function closeFullscreen() {
      const fullscreenNFT = document.querySelector('.nft-preview.fullscreen');
      if (fullscreenNFT) {
        fullscreenNFT.classList.remove('fullscreen');
        fullscreenNFT.querySelector('.fullscreen-content')?.remove();
      }
    }

    nfts.forEach(preview => {
      const originalClickHandler = (e) => {
        // Don't trigger if clicking mint button
        if (e.target.classList.contains('mint-button')) {
          return;
        }

        // Remove any existing fullscreen content
        closeFullscreen();

        preview.classList.add('fullscreen');

        const container = document.createElement('div');
        container.className = 'fullscreen-content';

        const nftName = preview.getAttribute('data-name');
        const contractKey = preview.getAttribute('data-contract-key')
          .toUpperCase()
          .replaceAll(' ', '')
          .trim();

        // Get contract info and check availability
        const contractInfo = nftManager.getContractInfo(contractKey);
        const isAvailable = contractInfo?.available ?? false;

        console.log('Contract Key:', contractKey);
        console.log('Contract Info:', contractInfo);
        console.log('Availability:', isAvailable);

        const details = document.createElement('div');
        details.className = 'nft-details';

        // Create availability message based on status
        const availabilityStatus = isAvailable
          ? '<div class="nft-availability available">Available for Minting</div>'
          : '<div class="nft-availability sold-out">Sold Out</div>';

        details.innerHTML = `
        <div class="nft-details-content">
          <h2 class="nft-title">${nftName}</h2>
          <h4 class="nft-title">1/1</h4>
          <div class="nft-price">Price: 20 STX</div>
          <div class="nft-artist">Artist: Reece Swanepoel</div>
          ${availabilityStatus}
          <button class="mint-button" ${!isAvailable ? 'disabled' : ''} data-contract="${contractKey}">
            ${isAvailable ? 'Mint NFT' : 'Sold Out'}
          </button>
        </div>
      `;

        const closeBtn = document.createElement('div');
        closeBtn.className = 'close-button';
        closeBtn.innerHTML = '×';

        container.appendChild(details);
        container.appendChild(closeBtn);
        preview.appendChild(container);

        // Add mint button listener only if NFT is available
        if (isAvailable) {
          const mintButton = details.querySelector('.mint-button');
          mintButton.addEventListener('click', async (e) => {
            e.stopPropagation();
            try {
              const response = await nftManager.mintNFT(contractKey);
              console.log('Minting response:', response);
              alert('NFT minting initiated! Check your wallet for confirmation.');
            } catch (error) {
              console.error('Minting error:', error);
              alert('Failed to mint NFT: ' + error.message);
            }
          });
        }

        closeBtn.addEventListener('click', (e) => {
          e.stopPropagation();
          closeFullscreen();
        });
      };

      preview.addEventListener('click', originalClickHandler);
    });

    // Debug: Log all contracts at initialization
    console.log('All NFT contracts:', nftManager.getAllContracts());
  }

  setupEventListeners() {
    window.addEventListener('hashchange', () => {
      console.log('Hash change detected');
      this.handleRoute();
    });

    window.addEventListener('popstate', () => {
      console.log('Pop state detected');
      this.handleRoute();
    });
  }

  createPageObject(pageData, tokenId, userAddressMainnet, blockHeight) {
    const isActive = typeof pageData.active === 'boolean'
      ? pageData.active
      : pageData.active?.type === 'true' ||
      pageData.active?.value === true ||
      pageData.active?.type === 3;

    return {
      type: 'page',
      id: tokenId,
      owner: pageData.owner.value,
      active: isActive,
      title: pageData.title.value,
      description: pageData.description.value,
      metadataUri: pageData['metadata-uri']?.value.data || '',
      url: `https://${userAddressMainnet}.gated.so/#/${pageData.title?.data || tokenId}`,
      blockHeight: blockHeight
    };
  }

  setupMusicEventListeners() {
    setTimeout(() => {
      const songItems = document.querySelectorAll('.song-list li');
      const playPauseBtn = document.getElementById('play-pause-btn');
      const prevBtn = document.getElementById('prev-btn');
      const nextBtn = document.getElementById('next-btn');
      const cardHeader = document.querySelector('.card-header');

      // Use window.musicPlayer instead of creating new audio
      if (!window.musicPlayer) {
        console.error('Music player not initialized');
        return;
      }

      songItems.forEach((item, index) => {
        item.addEventListener('click', (e) => {
          if (e.target.classList.contains('mint-track-btn')) return;

          // Use the main player methods
          window.musicPlayer.loadTrack(index, musicData);
          window.musicPlayer.togglePlayPause();

          songItems.forEach(i => i.classList.remove('active-track'));
          item.classList.add('active-track');

          if (cardHeader) {
            cardHeader.style.backgroundImage = `url(${musicData.songs[index].imgUrl})`;
          }
        });
      });

      // Use main player methods for buttons
      if (playPauseBtn) {
        playPauseBtn.addEventListener('click', () => window.musicPlayer.togglePlayPause());
      }

      if (prevBtn) {
        prevBtn.addEventListener('click', () => window.musicPlayer.previousTrack());
      }

      if (nextBtn) {
        nextBtn.addEventListener('click', () => window.musicPlayer.nextTrack());
      }

      // Add progress bar update
      window.musicPlayer.audioPlayer.addEventListener('timeupdate', () => {
        const progressBar = document.getElementById('progress-bar');
        if (progressBar && !isNaN(window.musicPlayer.audioPlayer.duration)) {
          const percentage = (window.musicPlayer.audioPlayer.currentTime / window.musicPlayer.audioPlayer.duration) * 100;
          progressBar.value = percentage;
        }
      });
    }, 100);
  }

  getCurrentStxAddress() {
    if (!this.auth) {
      console.error('Auth not initialized');
      return null;
    }
    if (!this.auth.isSignedIn()) {
      console.error('User not signed in');
      return null;
    }
    return this.auth.getCurrentAddress();
  }

  setupWalletFunctions() {

    // Single source of truth efor wallet connection handling
    // Clear any existing connection state
    this._connecting = false;

    // Remove previous handlers if they exist
    if (window._connectWalletHandler) {
      delete window.connectWallet;
      delete window.disconnectWallet;
    }

    // Single source of truth for wallet connection
    const handleConnect = async (isChange = false) => {
      console.log('Connect handler called', { isChange });

      if (this._connecting) {
        console.log('Connection already in progress');
        return;
      }

      try {
        this._connecting = true;
        return await this.auth.connectWallet(isChange);
      } finally {
        this._connecting = false;
      }
    };

    // Set up global functions
    window._connectWalletHandler = true;
    window.connectWallet = handleConnect;
    window.disconnectWallet = () => this.auth.disconnectWallet();

    // Remove any existing click handlers
    const connectButton = document.getElementById('connectWallet-button');
    if (connectButton) {
      const newButton = connectButton.cloneNode(true);
      connectButton.parentNode.replaceChild(newButton, connectButton);

      // Add single click handler that uses our handleConnect function
      newButton.addEventListener('click', () => handleConnect(false));
    }
  }

  async checkExistingSession() {
    console.log('Checking for existing session');
    if (this.auth) {
      const currentAddress = this.auth.initialize();
      if (currentAddress) {
        console.log('Found existing session with address:', currentAddress);
        this.currentStxAddress = currentAddress;
        await this.handleRoute();
      }
    }
  }


  init() {
    // First check if element exists
    this.mainContent = document.getElementById('main-container');

    // If it doesn't exist, create it
    if (!this.mainContent) {
      this.mainContent = document.createElement('div');
      this.mainContent.id = 'main-container';
      document.body.appendChild(this.mainContent);
    }

    // Now handle the initial route
    this.handleRoute();

    this.setupDeactivationHandlers();
  }

  initializePageState(toggle) {
    console.log('Step 2: Initializing settings page and toggle:', toggle.id);

    // Get the associated page card
    const pageCard = toggle.closest('.page-card');
    if (!pageCard) {
      console.warn('Page card not found for toggle:', toggle.id);
      return;
    }

    // Retrieve the save button and page data
    const saveBtn = pageCard.querySelector('.save-btn');
    if (!saveBtn || !saveBtn.dataset.page) {
      console.warn('Save button or page data missing for page card:', pageCard);
      return;
    }

    console.log('Raw page data before parsing:', saveBtn.dataset.page);

    try {
      const pageData = JSON.parse(saveBtn.dataset.page);
      console.log("Step 3: Getting Page data for edit page", pageData)

      // Check both festive mode and NFT from metadata
      const festiveMode = pageData?.metadata?.attributes?.find(
        attr => attr.trait_type === "Festive Mode" && attr.value === "On"
      );


      const festiveNFTData = {
        identifier: pageData?.metadata?.attributes?.find(
          attr => attr.trait_type === "Festive NFT"
        )?.value,
        metadata: pageData?.metadata?.properties?.meta?.content?.festive?.nft
      };

      console.log('Step 4: Getting Page-specific states:', { festiveMode, festiveNFTData });

      // Set toggle state based on metadata
      toggle.checked = festiveMode ? true : false;

      // If festive mode is enabled, initialize the UI

      console.log("Step 4.1: Confirming that according to Metadata, Festive Mode is ON");
      this.setupFestiveUI(pageCard, toggle, festiveNFTData);

      // Show/hide dropdown based on initial toggle state
      const dropdownContainer = pageCard.querySelector('.nft-dropdown-container');
      if (dropdownContainer) {
        dropdownContainer.style.display = toggle.checked ? 'block' : 'none';
      }

    } catch (error) {
      console.error('JSON Parse Error:', error);
      console.error('Attempted to parse:', saveBtn.dataset.page);
      return;
    }
  }

  initializeSettingsToggles() {
    document.querySelectorAll('.settings-cog').forEach(btn => {

      btn.addEventListener('click', e => {

        console.log("Step 1: Setting Cog Clicked")

        const toggle = e.target.closest('.page-card').querySelector('.festive-toggle');
        if (toggle) {
          this.initializePageState(toggle); // Initialize festive state when editing starts
        }

        const card = e.target.closest('.page-card');
        if (!card) return;

        const panel = card.querySelector('.settings-panel');
        if (!panel) return;

        // Close other open panels first
        document.querySelectorAll('.settings-panel:not(.none)').forEach(p => {
          if (p !== panel) p.classList.add('none');
        });

        panel.classList.toggle('none');

        // Reset form to original values if cancelling edit
        if (panel.classList.contains('none')) {
          this.resetFormToOriginal(card);
        }
      });
    });
  }

  setupFestiveUI(pageCard, toggle, festiveNFTData) {
    console.log('Step 5: Initializing UI for page card:', pageCard);

    console.log('Step 5.1: Creating the dropdown element');
    // Create the container structure
    let dropdownContainer = document.createElement('div');
    dropdownContainer.className = 'nft-dropdown-container';
    dropdownContainer.style.marginTop = '10px';
    dropdownContainer.style.display = toggle.checked ? 'block' : 'none';

    // Create loading indicator
    const loadingDiv = document.createElement('div');
    loadingDiv.textContent = 'Loading NFTs...';
    loadingDiv.className = 'loading-indicator';
    dropdownContainer.appendChild(loadingDiv);

    console.log('Step 5.2: Creating the select from dropdown element');
    // Create the select element
    const select = document.createElement('select');
    select.className = 'nft-select';
    select.style.display = 'none';  // Hide initially

    console.log('Step 5.3: Adding default option to dropdown');
    // Add default option
    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Select an NFT';
    select.appendChild(defaultOption);

    // If we have a current NFT, add it as selected
    console.log('Step 5.4: Setting current NFT as selected');
    if (festiveNFTData?.identifier) {
      const currentOption = document.createElement('option');
      currentOption.value = festiveNFTData.identifier;
      currentOption.textContent = `${festiveNFTData.metadata?.name || festiveNFTData.identifier.split('::')[1]} #${festiveNFTData.metadata?.imageUrl?.split('/').pop()?.replace('.png', '')}`;
      currentOption.dataset.imageUrl = festiveNFTData.metadata?.imageUrl;
      currentOption.selected = true;
      select.appendChild(currentOption);
    }

    console.log('Step 5.5: Adding everything to DOM');
    // Add the select to the container
    dropdownContainer.appendChild(select);

    // Add the dropdown to the DOM
    const settingsGroup = toggle.closest('.settings-group');
    if (settingsGroup) {
      // Remove any existing dropdown container first
      const existingContainer = settingsGroup.querySelector('.nft-dropdown-container');
      if (existingContainer) {
        existingContainer.remove();
      }
      settingsGroup.appendChild(dropdownContainer);
    }

    // Add toggle event listener
    toggle.addEventListener('change', (e) => {
      const isChecked = e.target.checked;
      dropdownContainer.style.display = isChecked ? 'block' : 'none';

      // If turning off, also remove the fall effect
      if (!isChecked && this.currentEffect) {
        document.body.removeChild(this.currentEffect.canvas);
        this.currentEffect = null;
      }
    });
    // Populate NFT options and handle loading state
    this.populateNFTOptions(dropdownContainer, festiveNFTData || {})
      .then(() => {
        // Remove loading indicator and show select
        loadingDiv.remove();
        select.style.display = 'block';
      })
      .catch(error => {
        console.error('Error populating NFTs:', error);
        loadingDiv.textContent = 'Error loading NFTs. Please try again.';
      });
  }

  // Helper function to check if an image loads

  checkImageLoads(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async populateNFTOptions(dropdownContainer, festiveNFTData) {
    const select = dropdownContainer.querySelector('.nft-select');
    if (!select) return;
    console.log("Step 6: Populating dropdown with NFTs");

    try {
      const userSession = new UserSession();
      const userData = userSession.loadUserData();
      const userAddress = userData.profile.stxAddress.mainnet;
      console.log("Step 6.1: Grabbing user data", userSession, userData, userAddress);

      const nftData = await this.fetchNFTs(userAddress);
      console.log("Step 6.2: NFTs Found:", nftData);

      if (!nftData || !nftData.results) {
        throw new Error('No NFT data available');
      }

      // Clear existing options except the first (default) option
      const defaultOption = select.querySelector('option[value=""]');
      const currentOption = select.querySelector('option[selected]');
      select.innerHTML = '';
      if (defaultOption) select.appendChild(defaultOption);
      if (currentOption) select.appendChild(currentOption);

      console.log("Step 6.5: Creating preview section");
      // Create or find preview div
      const previewDiv = dropdownContainer.querySelector('.nft-preview') || document.createElement('div');
      previewDiv.className = 'nft-preview';
      previewDiv.style.marginTop = '10px';

      // Add preview div if it doesn't exist
      if (!dropdownContainer.querySelector('.nft-preview')) {
        dropdownContainer.appendChild(previewDiv);
      }

      // Show current NFT preview if it exists
      if (festiveNFTData?.metadata?.imageUrl) {
        if (await this.checkImageLoads(festiveNFTData.metadata.imageUrl)) {
          previewDiv.innerHTML = `
                    <img src="${festiveNFTData.metadata.imageUrl}" 
                        alt="Current NFT Preview" 
                        style="max-width: 100px; height: auto; border-radius: 4px;">
                `;
        }
      }

      console.log("Step 6.8: Loading NFTs that have valid images ONLY");
      // Add all NFTs
      for (const nft of nftData.results) {
        const nftIdentifier = nft.asset_identifier;
        const nftId = nft.value.repr.replace('u', ""); // Remove the 'u' prefix
        console.log("6.8a NFT Identifier", nftIdentifier)
        console.log("6.8b NftId", nftId)
        const [contractAddress, assetName] = nftIdentifier.split('::');

        console.log(`6.8c Processing NFT: ${assetName} #${nftId}`);

        try {
          // Correct URL structure for Hiro API
          const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${nftId}`;
          const response = await fetch(metadataUrl);
          console.log('6.8d metadataUrl', metadataUrl)

          if (!response.ok) {
            console.log(`❌ Metadata fetch failed for ${assetName} #${nftId} with status ${response.status}`);
            continue;
          }

          const metadata = await response.json();
          const imageUrl = metadata.metadata.cached_thumbnail_image // Use the image URL from metadata
          console.log('6.8e metadata', metadata)
          console.log('6.8f imageUrl', metadata.metadata.cached_thumbnail_image)

          // If no image URL or image doesn't load, quietly skip
          if (!imageUrl || !(await this.checkImageLoads(imageUrl))) {
            console.log(`Skipping NFT ${assetName} #${nftId} - image not available`);
            continue;
          }




          const option = document.createElement('option');
          option.value = nftIdentifier;
          option.textContent = `${assetName} #${nftId}`;
          option.dataset.imageUrl = imageUrl;
          option.dataset.nftId = nftId;

          if (nftIdentifier === festiveNFTData?.identifier) {
            option.selected = true;
          }

          select.appendChild(option);

        } catch (error) {
          console.log(`Skipping NFT ${assetName} #${nftId} - error occurred`);
          continue;
        }
      }

      console.log("Step 6.9: Setting up change listener");
      // Add change event listener to select
      select.addEventListener('change', (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const imageUrl = selectedOption.dataset.imageUrl;

        if (imageUrl) {
          previewDiv.innerHTML = `
                    <img src="${imageUrl}" 
                        alt="NFT Preview" 
                        style="max-width: 100px; height: auto; border-radius: 4px;">
                `;

          // Update fall effect
          if (this.currentEffect) {
            document.body.removeChild(this.currentEffect.canvas);
            this.currentEffect = null;
          }
          this.currentEffect = initNFTFallEffect(imageUrl);
        } else {
          previewDiv.innerHTML = ''; // Clear preview if no image URL
        }
      });

    } catch (error) {
      console.error('Error populating NFT options:', error);
      throw error; // Propagate error to be handled by caller
    }
  }

  async findPageTransaction(cleanAddress, decodedTitle) {
    const limit = 50;
    let offset = 0;

    while (true) {
      console.log(`Searching transactions with offset: ${offset}`);
      const txResponse = await fetch(
        `https://stacks-node-api.mainnet.stacks.co/extended/v1/address/${cleanAddress}/transactions?limit=${limit}&offset=${offset}`
      );
      const txData = await txResponse.json();

      if (!txData.results || txData.results.length === 0) {
        console.log('No more transactions to search');
        break;
      }

      const pageTx = txData.results.find(tx => {
        const titleArg = tx.contract_call?.function_args?.[0]?.repr;


        // Remove quotes from blockchain title
        const cleanTitleArg = titleArg?.replace(/^"|"$/g, '');

        // console.log('Comparing:', {
        //   cleanTitleArg,
        //   decodedTitle,
        //   matches: cleanTitleArg === decodedTitle
        // });

        const normalizedTitleArg = titleArg?.toLowerCase().replace(/["\s-]/g, '');
        const normalizedSearchTitle = decodedTitle.toLowerCase().replace(/["\s-]/g, '');

        console.log('normalizedTitleArg', normalizedTitleArg)
        console.log('normalizedSearchTitle', normalizedTitleArg)

        return tx.contract_call?.function_name === 'mint-page' &&
          normalizedTitleArg === normalizedSearchTitle;
      });

      if (pageTx) {
        console.log('Found matching transaction at offset:', offset);
        return pageTx;
      }

      // If we got less than the limit, we've reached the end
      if (txData.results.length < limit) {
        console.log('Reached end of transactions');
        break;
      }

      offset += limit;
    }

    return null;
  }



  updatePageContent(page) {

    if (!this.authService) {
      this.authService = new AuthService(this);
    }

    const mainContent = document.getElementById('main-content');
    const { metadata } = page;

    // Debug logs for page info
    console.log('Current URL:', window.location.href);
    console.log('Page Title:', page.title);
    console.log('Page Object:', page);


    const isMochinesPage = (
      // Check for local development
      (window.location.href.includes('localhost:3000') &&
        (window.location.href.includes('sp1zcyg0d3hck2f7sy8vh9zreb0jwcbsapfns8v5z') ||
          window.location.href.includes('sp327amyaajfhdsdge6ad0htacyq4ccxjgt47m2h3')) &&
        page.title === 'M.O.C.H.I.N.E.S') ||
      // Check for production
      (window.location.href.includes('gated.so') &&
        (window.location.href.includes('sp1zcyg0d3hck2f7sy8vh9zreb0jwcbsapfns8v5z') ||
          window.location.href.includes('sp327amyaajfhdsdge6ad0htacyq4ccxjgt47m2h3')) &&
        page.title === 'M.O.C.H.I.N.E.S')
    );

    // Debug log for condition check
    console.log('Mochines Page Check:', {
      isLocalhost: window.location.href.includes('localhost:3000'),
      hasAddress: window.location.href.includes('sp1zcyg0d3hck2f7sy8vh9zreb0jwcbsapfns8v5z'),
      titleMatch: page.title === 'M.O.C.H.I.N.E.S',
      isMochinesPage: isMochinesPage
    });


    // Generate NFT grid HTML from MOCHINE_CONTRACTS
    const nftContractsHTML = Object.entries(MOCHINE_CONTRACTS)
      .map(([key, contract]) => `
        <div class="nft-preview" 
             data-name="${contract.name}" 
             data-contract-key="${key}">
          <img
            src="${contract.image}"
            alt="${contract.name}"
            class="nft-image"
            onerror="this.src='/api/placeholder/400/200'"
          >
        </div>
      `)
      .join('');

    const contentBlocksHTML = Object.entries(metadata?.properties?.meta?.content || {})
      .filter(([key, block]) => typeof block === 'object' && block?.type && block?.content)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([, block]) => `
    <div class="editable-block" data-type="${block.type}">
      ${block.type === 'heading'
          ? `<h2 class="editable">${block.content}</h2>`
          : `<p class="editable">${block.content}</p>`}
    </div>
  `).join('');


    // Build the main container
    const containerHTML = `
    <div class="main-container">
        <h1 id="bnsv2NameDisplay" class="bns-name">${page.title}</h1>
        <div class="ownership-section"></div>
      </div>
        ${metadata?.properties?.meta?.content?.socialLinks?.enabled ? `
        <div class="social-links-section mb-6">
          ${Object.entries(metadata.properties.meta.content.socialLinks.links || {})
          .filter(([_, url]) => url)
          .map(([platform, url]) => {
            const icon = {
              x: 'fa-brands fa-x-twitter',
              discord: 'fa-brands fa-discord',
              website: 'fa-solid fa-globe'
            }[platform];
            return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="social-link">
                <i class="${icon}"></i>
              </a>`;
          }).join('')}
        </div>
      ` : ''}
      <div class="page-description">${page.description}</div>
        <div class="page-content">
        ${contentBlocksHTML}
      </div>
${isMochinesPage ? `

 <div class="nft-collection-grid-container bg-gray-900 p-6 rounded-lg">
          <div class="nft-collection-grid">
            ${nftContractsHTML}
     </div>
          </div>
        ` : ''}
      </div>
    `;



    mainContent.innerHTML = containerHTML;

    // Update ownership section after DOM insertion
    const ownershipSection = document.querySelector('.ownership-section');
    if (ownershipSection && page.tx_id && cleanAddress) {
      ownershipSection.innerHTML = `
      <button onclick="window.open('https://explorer.hiro.so/txid/${page.tx_id}', '_blank', 'noopener,noreferrer')" 
        class="verify-button">
        <span class="verify-icon">✓</span>
        Owned by ${cleanAddress.slice(0, 4)}...${cleanAddress.slice(-4)}
      </button>
    `;
    }

    if (typeof initNFTInteractions === 'function') {
      initNFTInteractions(this.authService);
    }
  }

  initializeRouteHandlers() {

    const validateNFTImageUrl = async (url) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch (error) {
        console.error('Error validating NFT image URL:', error);
        return false;
      }
    };

    const cleanupNFTEffect = () => {
      if (window.currentNFTFallEffect) {
        try {
          document.body.removeChild(window.currentNFTFallEffect.canvas);
          window.currentNFTFallEffect = null;
          console.log('NFT Fall Effect cleaned up successfully');
        } catch (error) {
          console.error('Error cleaning up NFT Fall Effect:', error);
        }
      }
    };

    this.routeHandlers = {
      home: {
        match: (parsedUrl) => {
          // Only match home if user is NOT signed in
          const isSignedIn = this.auth?.isSignedIn();
          const matches = !parsedUrl.stxAddress && !parsedUrl.pageTitle && !isSignedIn;
          console.log('Home match check:', {

            hasStxAddress: this.auth?.currentAddress,
            hasPageTitle: !!parsedUrl.pageTitle,
            isSignedIn,
            matches
          });
          return matches;
        },
        render: () => this.renderHomePage()
      },
      // Update these two route handlers in your routeHandlers object

      dashboard: {
        match: (parsedUrl) => {
          // Get all possible address values for comparison
          const routerStxAddress = this.currentStxAddress?.toLowerCase();
          const windowStxAddress = window.stxAddress?.toLowerCase();
          const parsedAddress = parsedUrl.stxAddress?.toLowerCase();
          const currentStx = this.auth.currentAddress;



          console.log("currentSTX", currentStx);



          const noPageTitle = !parsedUrl.pageTitle;

          const isSignedIn = this.auth?.isSignedIn() ||
            this.auth?.userSession?.isUserSignedIn() ||
            localStorage.getItem('isAuthenticated') === 'true';

          console.log('Dashboard match check:', {
            noPageTitle,
            isSignedIn,
            currentStx: this.auth.currentAddress,
            localStorageStx: localStorage.getItem('authAddress')
          });

          // Show dashboard when address matches AND user is signed in AND there's no page title
          return isSignedIn && noPageTitle;
        },
        render: (currentStx) => this.renderAdminDashboard(this.auth.currentAddress)
      },

      claim: {
        match: (parsedUrl) => {
          // Get all possible address values for comparison
          const routerStxAddress = this.currentStxAddress?.toLowerCase();
          const windowStxAddress = window.stxAddress?.toLowerCase();
          const localStorageAddress = localStorage.getItem('authAddress')?.toLowerCase();
          const parsedAddress = parsedUrl.stxAddress?.toLowerCase();

          // Check if the URL address matches ANY of the user's addresses
          const addressMatches =
            (routerStxAddress && parsedAddress === routerStxAddress) ||
            (windowStxAddress && parsedAddress === windowStxAddress) ||
            (localStorageAddress && parsedAddress === localStorageAddress);

          const noPageTitle = !parsedUrl.pageTitle;
          const isSignedIn = this.auth?.isSignedIn() ||
            this.auth?.userSession?.isUserSignedIn() ||
            localStorage.getItem('isAuthenticated') === 'true';

          console.log('Claim match check:', {
            addressMatches,
            noPageTitle,
            isSignedIn,
            currentStx: this.currentStxAddress,
            windowStx: window.stxAddress,
            localStorageStx: localStorage.getItem('authAddress'),
            parsedStx: parsedUrl.stxAddress
          });

          // Changed logic: If user is looking at their own address but isn't signed in,
          // don't match this route (so they'll go to home instead)
          // Only match for viewing other people's addresses
          return parsedUrl.stxAddress && noPageTitle && !addressMatches;
        },
        render: (stxAddress) => this.renderClaimPage(stxAddress)
      },
      specificPage: {
        match: (parsedUrl) => {
          const isSpecificPage = parsedUrl.pageTitle?.toLowerCase() === 'when-walls-break';
          const allowedHosts = [
            'spmymhhc95ye7ezef19nnwszmhxej97zyhjb5nxv.localhost',
            'sp3v0kzbgk20cmee74ky0j0h0mhysgwvarekwmcpq.localhost',
            'sp3v0kzbgk20cmee74ky0j0h0mhysgwvarekwmcpq.gated.so',
            'blocktune.gated.so'
          ];
          const currentHost = window.location.hostname;
          const isAllowedHost = allowedHosts.includes(currentHost);

          return isSpecificPage && isAllowedHost;
        },
        render: async (stxAddress, pageTitle) => {
          // Show loader immediately
          loader.show();

          try {
            // Update loader text for different stages
            loader.updateText('Loading album metadata...');
            console.log("Album Metadata:", JSON.stringify(albumMetadata, null, 2));

            loader.updateText('Initializing music player...');
            // With this:
            if (!window.musicPlayer?.isInitialized) {
              // Create a proper instance of the music player
              window.musicPlayer = Object.create(musicPlayer);

              // Initialize audio player first
              window.musicPlayer.audioPlayer = new Audio();  // Add this line

              // Handle both preview time limit and track ending
              window.musicPlayer.audioPlayer.addEventListener('timeupdate', () => {
                if (window.musicPlayer.isPreviewMode &&
                  window.musicPlayer.audioPlayer.currentTime >= window.musicPlayer.MAX_PLAY_TIME) {
                  // When preview time limit is reached, move to next track
                  const nextIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
                  window.musicPlayer.setCurrentTrack(nextIndex);
                  window.musicPlayer.loadTrack(nextIndex, musicData);

                  // Auto-play next track
                  const playPromise = window.musicPlayer.audioPlayer.play();
                  if (playPromise) {
                    playPromise
                      .then(() => {
                        window.musicPlayer.startAudioTimer();
                        window.musicPlayer.startCountdown();
                        window.musicPlayer.updatePlayPauseButtons(true);
                      })
                      .catch(error => {
                        console.warn('Auto-play prevented:', error);
                        window.musicPlayer.updatePlayPauseButtons(false);
                      });
                  }
                }
              });

              // Add auto-play event listener before initialization
              window.musicPlayer.audioPlayer.addEventListener('ended', () => {
                const nextIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
                window.musicPlayer.setCurrentTrack(nextIndex);
                window.musicPlayer.loadTrack(nextIndex, musicData);

                // Auto-play with error handling
                const playPromise = window.musicPlayer.audioPlayer.play();
                if (playPromise) {
                  playPromise
                    .then(() => {
                      if (window.musicPlayer.isPreviewMode) {
                        window.musicPlayer.startAudioTimer();
                        window.musicPlayer.startCountdown();
                      }
                      window.musicPlayer.updatePlayPauseButtons(true);
                    })
                    .catch(error => {
                      console.warn('Auto-play prevented:', error);
                      window.musicPlayer.updatePlayPauseButtons(false);
                    });
                }
              });

              // Initialize after adding event listener
              window.musicPlayer.init();
            }

            loader.updateText('Preparing content...');
            this.mainContent.innerHTML = `
              <div class="profile-section">
              <div id="profileOuterContainer" class="profile-outer-container">
              <div id="profileInnerContainer" class="profile-inner-container">
              <div id="profilePhoto" class="profile-photo">

                <img src=${blocktuneLogo} alt="Gated Logo" class="logo" style="width:180px;" />
                </div>
                <div id="tipText" class="tip-text">Tip 1 STX</div>

              </div>
        </div>
                <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
      <div id="bnsv2NameDisplay" class="bns-name">
        <a href="https://x.com/thiskittyisgood" target="_blank">@goodkitty</a>
      </div>
    </div>
    <div class="page-wrapper">
        <div class="page-header">
        </div>
        <div class="page-description">
          <i>When Walls Break</i> features 8 original tracks
          <br>paired with 8 unique artworks inspired by the music.
          <br>This groundbreaking album redefines music ownership
          <br>with only 100 collector editions available.
          <br>Release date 19 Nov 2024.
        </div>
        <div id="store-container"></div>
        </div>
        <img src=${gatedLogo} alt="Gated Logo" class="logo" id="logoButton" style="width:100px;padding:40px;cursor:pointer;"/>
`;

            // Add your existing style additions
            const style = document.createElement('style');
            style.textContent = `
        .profile-outer-container {
          transform-origin: center;
          transition: transform 0.1s ease;
        }
      `;
            document.head.appendChild(style);

            loader.updateText('Loading When Walls Break...');
            const nftIntegration = {
              ...NFTAudioIntegration,
              auth: window.auth
            };

            const nftAvailability = await nftIntegration.initialize();
            console.log('NFT Availability:', nftAvailability);

            setTimeout(() => {
              try {
                if (!window.profilePhoto) {
                  window.profilePhoto = new ProfilePhoto();
                }
              } catch (error) {
                console.error('Error rendering specific page:', error);
              }
            }, 0);

            renderStoreItems([musicData], nftAvailability);
            this.setupMusicEventListeners();
            loader.hide();

          } catch (error) {
            console.error('Error rendering specific page:', error);
            this.mainContent.innerHTML = `
        <div class="error-message">
          Failed to load content. Please try again later.
        </div>
      `;

          }
        }
      },
      page: {
        match: (parsedUrl) => {
          return parsedUrl.stxAddress &&
            parsedUrl.hasPageTitle &&
            parsedUrl.pageTitle !== 'riseofthememe' &&
            parsedUrl.pageTitle !== 'when-walls-break';
        },
        render: async (stxAddress, pageTitle) => {
          cleanupNFTEffect();
          loader.show();

          try {
            const cleanAddress = stxAddress.toUpperCase();
            const searchTitle = decodeURIComponent(pageTitle).replace(/-/g, ' ');

            // Check if this is the MOCHINES page
            const isMochinesPage = (
              pageTitle === 'm-o-c-h-i-n-e-s' &&
              (cleanAddress === 'SP1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPFNS8V5Z' ||
                cleanAddress === 'SP327AMYAAJFHDSDGE6AD0HTACYQ4CCXJGT47M2H3')
            );

            console.log('isMochinesPage', isMochinesPage)
            // Force dark mode for MOCHINES page
            if (isMochinesPage) {
              initializeDarkModeToggle();
              document.body.classList.add('dark-mode');
              document.documentElement.classList.add('dark');  // For Tailwind dark mode

            }

            // Create main content element if it doesn't exist
            if (!this.mainContent) {
              this.mainContent = document.getElementById('main-content');
              if (!this.mainContent) {
                const mainContent = document.createElement('div');
                mainContent.id = 'main-content';
                document.body.appendChild(mainContent);
                this.mainContent = mainContent;
              }
            }

            // Fetch page data from smart contract
            const { pages } = await this.fetchNFTPages(cleanAddress);
            const matchingPage = pages.find(p => {
              const normalizedPageTitle = p.title?.toLowerCase().replace(/[.\-\s]/g, '');
              const normalizedSearchTitle = searchTitle.toLowerCase().replace(/[.\-\s]/g, '');
              console.log('Comparing:', normalizedPageTitle, 'with', normalizedSearchTitle);
              return normalizedPageTitle === normalizedSearchTitle;
            });
            console.log('Matching page:', matchingPage);

            if (matchingPage) {
              const metadata = await this.fetchPageMetadata(matchingPage.id);
              matchingPage.metadata = metadata;

              // First render the HTML structure
              this.mainContent.innerHTML = `
              <div class="profile-section">
                <div id="profileOuterContainer" class="profile-outer-container">
                  <div id="profileInnerContainer" class="profile-inner-container">
             <div id="profilePhoto" class="profile-photo">
          ${isMochinesPage
                  ? `<img src="${mochineBanner}" alt="Profile Photo" class="logo" style="width:180px;" />`
                  : ''}
                  <div id="tipText" class="tip-text">Tip 1 STX</div>
                  </div>
                </div>
                <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
              </div>
            `;
              this.updatePageContent(matchingPage);


              // Then initialize components
              setTimeout(async () => {
                if (window.profilePhoto) {
                  await window.profilePhoto.cleanup();
                }
                const profilePhoto = new ProfilePhoto();
                await profilePhoto.init();
                window.profilePhoto = profilePhoto;
              }, 500);

              // Initialize sidebar separately
              setTimeout(async () => {
                window.editSidebar = new EditSidebar(this);
                await window.editSidebar.updateVisibility(matchingPage, cleanAddress);
              }, 600);
            }

          } catch (error) {
            console.error('Page render error:', error);
            this.mainContent.innerHTML = `
              <div class="error-container">
                <h2>Error Loading Page</h2>
                <p>Unable to load page content. Please try again.</p>
              </div>
            `;
          } finally {
            loader.hide();
          }
        }
      },
      riseofthememe: {
        match: (parsedUrl) => {
          return parsedUrl.pageTitle === 'riseofthememe';
        },
        render: async (stxAddress, pageTitle) => {
          loader.show();

          try {
            loader.updateText('Loading Rise of the Meme...');

            // First set the base HTML structure
            this.mainContent.innerHTML = `
        <div id="page-container">
          <div class="profile-section">
            <div id="profileOuterContainer" class="profile-outer-container">
              <div id="profileInnerContainer" class="profile-inner-container">
                <div id="profilePhoto" class="profile-photo risememe-photo">
                  <img src="https://images.gamma.io/cdn-cgi/image/quality=100,width=300,height=300/https://images.gamma.io/ipfs/QmXFcWnqAiCnkx23riPZH8Tm6YX3MQkB9yeMNQ8tQENGoj" alt="Profile" class="logo" style="width:180px;" />
                </div>
                <div id="tipText" class="tip-text">Tip 1 STX</div>
              </div>
            </div>
            <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
            <div id="bnsv2NameDisplay" class="bns-name">
              <a href="https://x.com/RiseMemeTribes" target="_blank">@RiseMemeTribes</a>
            </div>
          </div>

          <div class="page-wrapper">
            <div class="page-header"></div>
            <div class="page-description">
              <i>Rise of the Meme</i> is a revolutionary NFT collection celebrating internet culture.
              <br/>Each piece captures iconic meme moments in digital history.
              <br/>Limited to 100 editions per collection.
              <br/>Launch date: December 2024
            </div>
            <div id="nft-swap-container" class="p-6 max-w-2xl mx-auto">
              <!-- Add a placeholder text to verify the container exists -->
              Loading NFT Swap...
            </div>
          </div>
          <div class="logo-container">
          <img src="${gatedLogo}" alt="Gated Logo" class="logo" id="logoButton" style="width:100px;padding:40px;cursor:pointer;"/>
          </div>
        </div>

      `;

            // Initialize NFT display
            const displayNFTs = async () => {
              try {
                const nftContainer = document.getElementById('nft-container');
                if (!nftContainer) return;

                // Get NFT details using the provided address
                const nftDetails = await getNFTDetails(stxAddress);

                if (nftDetails.success && nftDetails.nfts.length > 0) {
                  const nftsWithMetadata = await Promise.all(nftDetails.nfts.map(async (nft) => {
                    const metadata = await fetchNFTMetadata(nft.ipfsUrl);
                    const imageUrl = metadata?.image ?
                      metadata.image.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/') :
                      null;

                    return {
                      ...nft,
                      name: metadata?.name || `Rise of the Meme #${nft.tokenId}`,
                      imageUrl: imageUrl
                    };
                  }));

                  // Create HTML for each NFT
                  nftsWithMetadata.forEach(nft => {
                    const nftCard = document.createElement('div');
                    nftCard.className = 'bg-white rounded-lg shadow-md p-4';
                    nftCard.innerHTML = `
                                <h3 class="text-lg font-semibold mb-2">${nft.name}</h3>
                                ${nft.imageUrl ?
                        `<img src="${nft.imageUrl}" alt="${nft.name}" class="w-full h-auto rounded-md mb-2">` :
                        '<div class="bg-gray-200 w-full h-48 rounded-md mb-2"></div>'
                      }
                                <p class="text-sm text-gray-600">Token ID: ${nft.tokenId}</p>
                            `;
                    nftContainer.appendChild(nftCard);
                  });
                } else {
                  nftContainer.innerHTML = '<p class="text-gray-600">No Rise of the Meme NFTs found in your wallet.</p>';
                }
              } catch (error) {
                console.error('Error displaying NFTs:', error);
                nftContainer.innerHTML = '<p class="text-red-600">Error loading NFTs. Please try again later.</p>';
              }
            };

            // Wait for DOM to be ready
            await new Promise(resolve => setTimeout(resolve, 100));

            // Initialize profile photo only after DOM is ready and user session exists
            const initializeProfile = async () => {
              try {
                // Make sure all required elements exist
                const requiredElements = [
                  'profileOuterContainer',
                  'profileInnerContainer',
                  'profilePhoto',
                  'tipText',
                  'clickSound'
                ];

                const missingElements = requiredElements.filter(id => !document.getElementById(id));
                if (missingElements.length > 0) {
                  console.error('Missing elements:', missingElements);
                  return;
                }

                // Initialize profile photo with proper user session check
                if (!window.profilePhoto && window.userSession) {
                  window.profilePhoto = new ProfilePhoto(window.userSession);
                }
              } catch (error) {
                console.error('Error initializing profile:', error);
              }
            };

            // Initialize the NFT swap component
            const nftSwapContainer = document.getElementById('nft-swap-container');
            if (nftSwapContainer && window.NFTSwapModule) {
              console.log("Initializing NFT swap component");
              const router = this; // 'this' is the router instance

              console.log("Router state:", {
                hasAuth: !!router.auth,
                isSignedIn: router.auth?.isSignedIn()
              });

              // Only initialize if auth is ready
              if (!router.auth) {
                console.error('Auth not initialized in router');
                return;
              }

              window.NFTSwapModule.init(nftSwapContainer, router);
            } else {
              console.error("Could not initialize NFT swap: ", {
                containerExists: !!nftSwapContainer,
                moduleExists: !!window.NFTSwapModule
              });
            }




            // Call initialization after a slight delay to ensure DOM is ready
            // Initialize everything
            setTimeout(() => {
              initializeProfile();
              displayNFTs();
            }, 200);

            loader.hide();

          } catch (error) {
            console.error('Error rendering Rise of the Meme page:', error);
            this.mainContent.innerHTML = `
        <div class="error-message">
          Failed to load content. Please try again later.
        </div>
      `;
            loader.hide();
          }
        }
      }
    }



  }



  async fetchPageMetadata(tokenId) {
    console.log('🔍 Attempting to fetch metadata for token ID:', tokenId);

    const tokenUriOptions = {
      contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
      contractName: 'gated-pages',
      functionName: 'get-token-uri',
      functionArgs: [uintCV(tokenId)],
      network: STACKS_MAINNET,
      senderAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5'
    };

    try {
      const tokenUriResult = await fetchCallReadOnlyFunction(tokenUriOptions);
      console.log('Token URI Result for ID', tokenId, ':', tokenUriResult);

      if (tokenUriResult?.value?.value?.value) {
        const ipfsUri = tokenUriResult.value.value.value;
        const httpUrl = ipfsUri.replace('ipfs://', 'https://ipfs.io/ipfs/');

        const metadataResponse = await fetch(httpUrl);
        if (!metadataResponse.ok) {
          throw new Error('Failed to fetch metadata');
        }

        const metadata = await metadataResponse.json();
        console.log('Metadata for token', tokenId, ':', metadata);
        return metadata;
      }
    } catch (error) {
      console.error('Error fetching metadata for token', tokenId, ':', error);
      return null;
    }
  }


  checkExistingSession() {
    if (this.auth.userSession.isUserSignedIn()) {
      const userData = this.auth.userSession.loadUserData();
      const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
      this.currentStxAddress = userData.profile.stxAddress[network];
    }
  }


  initialize() {
    this.initializePageHandlers();
    this.setupGlobalErrorHandler();
  }

  initializePageHandlers() {
    // Unbind existing handlers to prevent memory leaks
    this.removeExistingHandlers();

    // Initialize all handlers
    this.initializeSettingsToggles();
    this.initializeSaveButtons();
    this.initializeCancelButtons();
  }

  removeExistingHandlers() {
    document.querySelectorAll('.settings-cog, .save-btn, .cancel-btn, .toggle-status')
      .forEach(element => {
        const clone = element.cloneNode(true);
        element.parentNode.replaceChild(clone, element);
      });
  }


  initializeSaveButtons() {
    document.querySelectorAll('.save-btn').forEach(btn => {
      btn.addEventListener('click', async e => {
        try {
          const button = e.target;
          if (this.pendingUpdates.has(button)) return;

          this.pendingUpdates.add(button);
          button.disabled = true;
          button.textContent = 'Submitting...';

          if (this.currentEffect) {
            document.body.removeChild(this.currentEffect.canvas);
            this.currentEffect = null;
          }

          const panel = button.closest('.settings-panel');
          const pageData = this.getPageDataFromPanel(panel);

          if (!this.validatePageData(pageData)) {
            throw new Error('Invalid page data');
          }

          // Submit transaction
          await this.updatePage(pageData);

        } catch (error) {
          this.handleError('Failed to submit update', error);
        } finally {
          const button = e.target;
          button.textContent = 'Update Page';
          button.disabled = false;
          this.pendingUpdates.delete(e.target);
        }
      });
    });
  }


  getPageDataFromPanel(panel) {
    console.log("Step 7: Collecting and Sending data from Settings Page to Confirmation Page")

    const titleInput = panel.querySelector('input[type="text"]');
    const descriptionInput = panel.querySelector('textarea');
    const saveBtn = panel.querySelector('.save-btn');
    const pageCard = panel.closest('.page-card');

    // Get contract type from the dedicated data attribute
    const contractType = saveBtn.dataset.contractType;
    if (!titleInput || !descriptionInput || !saveBtn || !pageCard) {
      throw new Error('Missing required form elements');
    }

    let id = null;
    try {
      const partialData = saveBtn.dataset.page;
      const idMatch = partialData.match(/"id":(\d+)/);
      if (idMatch) {
        id = parseInt(idMatch[1], 10);
      }
    } catch (error) {
      console.error('Error extracting ID:', error);
    }

    // Get festive mode state and NFT details
    const festiveToggle = pageCard.querySelector('.festive-toggle');
    let festiveData = {};

    if (festiveToggle && festiveToggle.checked) {
      const dropdownContainer = pageCard.querySelector('.nft-dropdown-container');
      const select = dropdownContainer?.querySelector('.nft-select');

      if (select && select.selectedIndex > 0) {
        const selectedOption = select.options[select.selectedIndex];
        festiveData = {
          festiveMode: true,
          festiveNFT: {
            identifier: selectedOption.value,
            name: selectedOption.textContent,
            imageUrl: selectedOption.dataset.imageUrl,
            nftId: selectedOption.dataset.nftId
          }
        };
      }
    }

    const pageData = {
      id: id,
      contractType: contractType, // Use the separate data attribute
      title: titleInput.value.trim(),
      description: descriptionInput.value.trim(),
      active: true,
      slug: this.generateSlug(titleInput.value),
      ...festiveData  // Include festive data if it exists

    };

    console.log('Step 7.1: Assembled page data:', pageData);
    return pageData;
  }

  async updatePage(pageData) {
    console.log("Step 7.2: Validate and initiates the confirmation process")

    if (!this.currentStxAddress) {
      throw new Error('No wallet connected');
    }

    try {
      // First show confirmation modal
      await this.showUpdateConfirmation(
        pageData.id,
        pageData.title,
        pageData.description,
        pageData.slug || this.sanitizeUrl(pageData.title),
        this.currentStxAddress,
        pageData.active,
        pageData.contractType
      );
    } catch (error) {
      console.error('Error in update flow:', error);
      throw error;
    }
  }

  showNotification(message) {
    const notification = document.createElement('div');
    notification.className = 'notification';
    notification.textContent = message;
    document.body.appendChild(notification);

    setTimeout(() => notification.remove(), 5000);
  }

  validatePageData(pageData) {
    const { title, description } = pageData;

    if (!title || title.length > 64) return false;
    if (!description || description.length > 256) return false;

    // Check for valid characters in title (matches smart contract requirements)
    const validTitleRegex = /^[a-zA-Z0-9-]+$/;
    return validTitleRegex.test(this.generateSlug(title));
  }

  generateSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }



  updateUIAfterSave(card, pageData) {
    if (!card) {
      console.warn('No card element found');
      return;
    }
    console.log('Updating UI with page data:', pageData);

    // Store the data on both the card and the save button
    const safePageData = {
      id: pageData.id,
      contractType: pageData.contractType,
      title: String(pageData.title || '').trim(),
      description: String(pageData.description || '').trim(),
      active: true,
      slug: pageData.slug || ''
    };

    // Store data on the card itself
    try {
      card.dataset.page = JSON.stringify(safePageData);
    } catch (error) {
      console.error('Failed to save data to card:', error);
    }


    const saveBtn = card.querySelector('.save-btn');
    if (saveBtn) {
      // Store contract type separately
      saveBtn.dataset.contractType = pageData.contractType;

      const safePageData = {
        id: pageData.id,
        title: String(pageData.title || '').trim(),
        description: String(pageData.description || '').trim(),
        active: true,
        slug: pageData.slug || '',
        metadata_uri: pageData.metadata_uri || pageData.metadata?.uri || ''
      };

      try {
        // Properly escape the data
        const jsonString = JSON.stringify(safePageData)
          .replace(/'/g, "\\'") // Escape single quotes
          .replace(/"/g, '\\"'); // Escape double quotes
        saveBtn.dataset.page = jsonString;
      } catch (error) {
        console.error('Failed to save data:', error);
      }
    }


    // Update title section
    const h3 = card.querySelector('.page-title h3');
    if (h3) h3.textContent = pageData.title || `Page #${pageData.id}`;

    // Update description
    const description = card.querySelector('.dashboard-page-description');
    if (description) description.textContent = pageData.description || 'No description available';

    // Update status badge in the correct location
    const statusBadge = card.querySelector('.status-badge');
    if (statusBadge) {
      statusBadge.className = `status-badge ${pageData.active ? 'active' : 'inactive'}`;
      statusBadge.textContent = pageData.active ? 'Active' : 'Inactive';
    }

    // Update settings panel inputs
    const titleInput = card.querySelector('.page-title-input');
    if (titleInput) {
      titleInput.value = pageData.title;
    }

    const descriptionInput = card.querySelector('.page-description-input');
    if (descriptionInput) {
      descriptionInput.value = pageData.description || '';
    }

    // Update character counters
    const titleCounter = card.querySelector(`#edit-title-counter-${pageData.id}`);
    if (titleCounter) {
      titleCounter.textContent = (pageData.title || '').length;
    }

    const descriptionCounter = card.querySelector(`#edit-description-counter-${pageData.id}`);
    if (descriptionCounter) {
      descriptionCounter.textContent = (pageData.description || '').length;
    }

    // Update festive toggle if it exists
    const festiveToggle = card.querySelector(`#festive-${pageData.id}`);
    if (festiveToggle && pageData.metadata?.attributes) {
      const isFestive = pageData.metadata.attributes.find(
        attr => attr.trait_type === "Festive Mode"
      )?.value === "On";
      festiveToggle.checked = isFestive;
    }



    // Ensure settings panel is hidden
    const settingsPanel = card.querySelector('.settings-panel');
    if (settingsPanel) {
      settingsPanel.classList.add('none');
    }

    // Make sure all classes are preserved
    card.className = 'page-card';
    const pageHeader = card.querySelector('.page-header');
    if (pageHeader) {
      pageHeader.className = 'page-header';
    }
    const pageTitle = card.querySelector('.page-title');
    if (pageTitle) {
      pageTitle.className = 'page-title';
    }
    const pageActions = card.querySelector('.page-actions');
    if (pageActions) {
      pageActions.className = 'page-actions';
    }
  }


  resetFormToOriginal(card) {
    const panel = card.querySelector('.settings-panel');
    const originalData = JSON.parse(panel.querySelector('.save-btn').dataset.page || '{}');

    const titleInput = panel.querySelector('input[type="text"]');
    const descriptionInput = panel.querySelector('textarea');
    const activeToggle = panel.querySelector('input[type="checkbox"]');

    if (titleInput) titleInput.value = originalData.title || '';
    if (descriptionInput) descriptionInput.value = originalData.description || '';
    if (activeToggle) activeToggle.checked = originalData.active || false;
  }

  initializeCancelButtons() {
    document.querySelectorAll('.cancel-btn').forEach(btn => {
      btn.addEventListener('click', e => {
        // Modal close handlers

        const modal = document.getElementById('create-page-modal');
        const form = document.getElementById('create-page-form');
        if (modal) {
          modal.style.display = 'none';
          if (form) {
            form.reset();
            // Reset the counter displays
            document.getElementById('title-counter').textContent = '0';
            document.getElementById('description-counter').textContent = '0';
          }
        }


        if (this.currentEffect) {
          document.body.removeChild(this.currentEffect.canvas);
          this.currentEffect = null;
        }
        const panel = e.target.closest('.settings-panel');
        if (panel) {
          panel.classList.add('none');
          this.resetFormToOriginal(panel.closest('.page-card'));
        }
      });
    });
  }

  setupGlobalErrorHandler() {
    window.addEventListener('unhandledrejection', event => {
      this.handleError('An unexpected error occurred', event.reason);
    });
  }

  handleError(message, error) {
    console.error(message, error);

    const errorElement = document.createElement('div');
    errorElement.className = 'error-toast';
    errorElement.textContent = `${message}: ${error.message || 'Unknown error'}`;

    document.body.appendChild(errorElement);
    setTimeout(() => errorElement.remove(), 5000);
  }

  getEmptyState() {
    return `
      <div class="empty-state">
        <p>No pages found. Create your first page!</p>
        <button class="create-page-btn">Create Page</button>
      </div>
    `;
  }

  getErrorState(stxAddress) {
    return `
      <div class="error-state">
        <p>Error loading your pages. Please try again.</p>
        <button onclick="window.router.loadPages('${this.escapeHtml(stxAddress)}')" class="retry-btn">
          Retry
        </button>
      </div>
    `;
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }


  initializeMusicPlayer() {
    console.log('Router: Starting music player initialization with auth:', this.auth);

    if (!window.musicPlayer) {
      window.musicPlayer = { ...musicPlayer };
      window.musicPlayer.auth = this.auth;  // Set auth on window.musicPlayer

      if (!window.musicPlayer.isInitialized) {
        window.musicPlayer.init();
      }
    }

    // Ensure auth is set even if musicPlayer already exists
    window.musicPlayer.auth = this.auth;
  }

  async handleRoute() {

    const isSignedIn = this.auth.userSession.isUserSignedIn();

    if (!isSignedIn) {
      console.log('No wallet detected');
    }

    if (!this.mainContent) {
      console.error('Main content element not initialized!');
      return;
    }

 
    try {
      const parsedUrl = this.parseUrl();
      console.log('Starting route handling with:', parsedUrl);

      // Check stored auth state first - make sure this is up to date
      if (this.auth) {
        const authStateUpdated = this.auth.checkStoredAuthState();
        console.log('Auth state check result:', authStateUpdated);
        console.log('Auth state stx address:', this.auth.stxAddress);
      }

      // Dashboard check with enhanced auth verification
      if (parsedUrl.stxAddress) {
        // Get the current auth status - force a fresh check
        const currentAddress = this.auth?.getCurrentAddress()?.toLowerCase();
        const parsedAddress = parsedUrl.stxAddress?.toLowerCase();
        const isSignedIn = this.auth?.isSignedIn();

        console.log('Dashboard auth check:', {
          currentAddress,
          parsedAddress,
          isSignedIn,
          hasStoredAuth: Boolean(localStorage.getItem('authAddress')),
          userSessionSignedIn: this.auth?.userSession.isUserSignedIn()
        });

        // Check if addresses match and user is viewing their own address
        if (currentAddress === parsedAddress) {
          // IMPORTANT FIX: Check the auth state more thoroughly
          const userSessionSignedIn = this.auth?.userSession.isUserSignedIn();
          const hasAuthInStorage = localStorage.getItem('isAuthenticated') === 'true';

          // If ANY auth indicator is positive, treat as signed in
          if ((isSignedIn || userSessionSignedIn || hasAuthInStorage) && !parsedUrl.pageTitle) {
            console.log('User authenticated, rendering dashboard');
            await this.renderAdminDashboard(parsedUrl.stxAddress);
            return;
          }
          // // Only show claim page if absolutely sure user is not signed in
          // else if (!isSignedIn && !userSessionSignedIn && !hasAuthInStorage) {
          //   console.log('User not authenticated, rendering claim page');
          //   await this.renderClaimPage(parsedUrl.stxAddress);
          //   return;
          // }
        }
        // This is an important case - user is authenticated but viewing someone else's address
        else if (isSignedIn && currentAddress !== parsedAddress) {
          console.log('Authenticated user viewing other address, rendering claim page');
          await this.renderClaimPage(parsedUrl.stxAddress);
          return;
        }
      }

      // Route matching with detailed logging
      console.log('Checking routes for:', parsedUrl);
      const matchingRoute = Object.entries(this.routeHandlers)
        .find(([name, handler]) => {
          console.log(`Checking route '${name}'...`);
          if (!handler || typeof handler.match !== 'function') {
            console.error(`Invalid handler for route '${name}'`);
            return false;
          }
          const matches = handler.match(parsedUrl);
          console.log(`Route '${name}' match result:`, matches);
          return Boolean(matches);
        });

      if (matchingRoute) {
        console.log('Rendering route:', matchingRoute[0]);
        await matchingRoute[1].render(parsedUrl.stxAddress, parsedUrl.pageTitle);
        return;
      }

      console.log('No matching route found for:', parsedUrl);
    } catch (error) {
      console.error('Error handling route:', error);
    }
  }

  async checkPageExists(stxAddress) {
    try {
      // Implement check against your smart contract
      // Example using your gated smart contract:
      const contractCall = await this.makeContractCall('get-page-by-id', [stxAddress]);
      return contractCall && contractCall.success;
    } catch (error) {
      console.error('Error checking page existence:', error);
      return false;
    }
  }

  // Add this method to your Router class or update if it exists
  async checkExistingSession() {
    try {
      if (!this.auth) {
        console.error('Auth service not initialized');
        return false;
      }

      // Check if user is signed in
      const isSignedIn = this.auth.isSignedIn();
      const userSessionSignedIn = this.auth.userSession.isUserSignedIn();
      const hasAuthInStorage = localStorage.getItem('isAuthenticated') === 'true';

      console.log('Session check:', { isSignedIn, userSessionSignedIn, hasAuthInStorage });

      // If any auth indicator is positive, user is signed in
      if (isSignedIn || userSessionSignedIn || hasAuthInStorage) {
        // Get current user address
        const userAddress = this.auth.getCurrentAddress();

        if (userAddress) {
          this.currentStxAddress = userAddress;
          window.stxAddress = userAddress;

          // Check if we're already on the correct subdomain
          const currentHostname = window.location.hostname;
          const userSubdomain = userAddress.toLowerCase();

          // If not on user's subdomain, redirect
          if (!currentHostname.startsWith(userSubdomain)) {
            console.log('User authenticated but on wrong subdomain, redirecting');
            this.auth.redirectToUserSubdomain();
            return true;
          }

          console.log('User authenticated and on correct subdomain');
          return true;
        }
      }

      console.log('User not authenticated');
      return false;
    } catch (error) {
      console.error('Error checking session:', error);
      return false;
    }
  }

  parseUrl() {
    const host = window.location.hostname;
    const hash = window.location.hash.slice(1) || '/';
    const pathname = window.location.pathname;
    const storedAddress = this.auth?.currentAddress || localStorage.getItem('authAddress');

    console.log('Auth state debug1:', this.auth.currentAddress)
    console.log('Auth state debug2:', {
      isSignedIn: this.auth?.userSession.isUserSignedIn(),
      localStorageAuth: localStorage.getItem('isAuthenticated'),
      localStorageAddr: localStorage.getItem('authAddress')
    });

    console.log('Parsing URL:', {
      host,
      hash,
      pathname,
      storedAddress,
      fullUrl: window.location.href
    });

    // Handle auth redirect first
    if (pathname === '/auth') {
      if (this.auth?.userSession.isSignInPending()) {
        this.auth.handlePendingSignIn();
      }
      window.location.href = '/';
      return { stxAddress: null, pageTitle: null };
    }

    // Extract STX address from subdomain
    const stxAddressMatch = host.match(/^([a-zA-Z0-9]+)\.(localhost|gated\.so)$/);
    const stxAddress = stxAddressMatch ? stxAddressMatch[1] : storedAddress;

    // Improve page title parsing
    const hasPageTitle = hash.startsWith('/');
    const rawPageTitle = hasPageTitle ? hash.slice(1) : hash;
    const pageTitle = rawPageTitle ? decodeURIComponent(rawPageTitle) : null;

    const parsed = {
      stxAddress,
      pageTitle,
      hasPageTitle,
      authAddress: storedAddress,
      isProduction: host.includes('gated.so')
    };
    console.log('Parsed URL result:', parsed);  // Add this debug log
    const isSubdomain = host.match(/^([a-zA-Z0-9]+)\.(localhost|gated\.so)$/);

    // If we have an authenticated address but aren't on a subdomain, redirect
    if (storedAddress && !isSubdomain && storedAddress !== "null") {
      const isLocalhost = host.includes('localhost');
      const baseHost = isLocalhost ? 'localhost:3000' : 'gated.so';
      const protocol = isLocalhost ? 'http://' : 'https://';
      const newUrl = `${protocol}${storedAddress}.${baseHost}${pathname}${hash ? '#' + hash : ''}`;

      console.log(`Redirecting to subdomain: ${newUrl}`);
      window.location.href = newUrl;
    }

    return parsed;
  }


  setCurrentStxAddress(address) {
    this.currentStxAddress = address;
    if (this.mainContent) {
      this.handleRoute();
    }
  }

  navigate(path, stxAddress = null) {
    const baseUrl = stxAddress
      ? `${window.location.protocol}//${stxAddress}.${window.location.host}`
      : window.location.origin;

    const newUrl = `${baseUrl}${path}`;
    window.history.pushState({}, '', newUrl);
    this.handleRoute();
  }

  navigateToAdmin(currentAddress) {
    console.log('Navigating to admin with:', {
      currentAddress,
      isSignedIn: this.auth.isSignedIn(),
      authState: this.auth,
      currentLocation: window.location.href
    });

    if (!currentAddress) {
      console.error('No current address available for navigation');
      return;
    }

    // Get the base domain depending on environment
    const baseUrl = window.location.hostname.includes('localhost')
      ? 'localhost:3000'
      : 'gated.so';

    // Construct the dashboard URL
    const dashboardUrl = `${window.location.protocol}//${currentAddress}.${baseUrl}`;

    console.log('Constructed dashboard URL:', dashboardUrl);

    // Before navigation, verify auth state
    if (!this.auth.isSignedIn()) {
      console.warn('User not signed in before navigation');
      return;
    }

    // Navigate to dashboard
    window.location.href = dashboardUrl;
  }

  getPageUrl(stxAddress, pageTitle) {
    const domain = window.location.hostname.includes('gated.so') ? 'gated.so' : 'localhost';
    return `${window.location.protocol}//${stxAddress}.${domain}/#/${pageTitle}`;
  }

  // You might also want to update the getDashboardUrl method to match:
  getDashboardUrl(stxAddress) {
    // Get current user data
    const userData = this.auth.userSession.loadUserData();
    const connectedAddress = userData.profile.stxAddress;

    // Check if we're on testnet or mainnet by comparing addresses
    const isTestnet = connectedAddress.testnet.toLowerCase() === stxAddress.toLowerCase();

    // Set appropriate domain
    const domain = isTestnet ? 'localhost:3000' : 'gated.so';

    console.log('Dashboard URL generation:', {
      stxAddress,
      testnetAddress: connectedAddress.testnet,
      mainnetAddress: connectedAddress.mainnet,
      isTestnet,
      domain
    });

    // Return the full URL
    return `${window.location.protocol}//${stxAddress}.${domain}`;
  }

  renderHomePage() {

    if (this.auth?.isSignedIn() && this.auth.getCurrentAddress()) {
      console.log('User is signed in, redirecting from home to dashboard');
      const stxAddress = this.auth.getCurrentAddress();
      this.renderAdminDashboard(stxAddress);
      return;
    }

    console.log('Starting renderHomePage');
    console.log('Auth object:', this.auth);


    const isSignedIn = this.auth.isSignedIn();
    const currentAddress = this.auth.getCurrentAddress();



    this.mainContent.innerHTML = `
    <div class="home-page">
        <img src=${gatedLogo} alt="Gated Logo" class="logo" style="width:400px;padding:40px;cursor:auto;"/>
        ${isSignedIn ? `
            <div id="wallet-container">
                <div class="dropdown">
                    <div class="button-group">
                        <button 
                            id="dashboard-nav-btn"
                            class="dashboard-btn"
                        >
                            Go to Dashboard
                        </button>
                        <button 
                            id="wallet-dropdown-btn" 
                            class="dropdown-toggle-btn"
                            aria-label="Toggle menu"
                        >
                            <svg class="chevron-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </button>
                    </div>
                    <div id="wallet-dropdown-content" class="dropdown-content">
                        <a id="disconnect-wallet-btn" class="dropdown-item">Disconnect</a>
                    </div>
                </div>
            </div>
        ` : `
            <button id="connectWallet-button" class="connect-btn">
                Connect Wallet
            </button>
        `}
    </div>
`;

    // Add event listeners after rendering HTML
    if (isSignedIn) {
      // Dashboard navigation
      const dashboardBtn = document.getElementById('dashboard-nav-btn');
      if (dashboardBtn) {
        dashboardBtn.addEventListener('click', () => {
          this.navigateToAdmin(currentAddress);
        });
      }

      // Setup wallet dropdown
      this.setupWalletDropdown();


      // Disconnect button
      const disconnectBtn = document.getElementById('disconnect-wallet-btn');
      if (disconnectBtn) {
        disconnectBtn.addEventListener('click', () => {
          window.disconnectWallet();
        });
      }
    } else {
      // Connect button
      const connectButton = document.getElementById('connectWallet-button');
      if (connectButton) {
        connectButton.addEventListener('click', () => {
          window.connectWallet();
        });
      }
    }
  }
  async loadPages(stxAddress) {
    const pagesList = document.getElementById('pages-list');
    pagesList.innerHTML = '<div class="loading">Searching blockchain for pages you own...</div>';

    try {
      const { pages } = await this.fetchNFTPages();
      console.log('Raw pages from blockchain:', pages);

      if (!pages?.length) {
        pagesList.innerHTML = this.getEmptyState();
        return;
      }

      // Filter pages to ensure ownership
      const userOwnedPages = pages.filter(page =>
        page.owner?.toLowerCase() === stxAddress.toLowerCase()
      );
      console.log(`Pages owned by ${stxAddress}:`, userOwnedPages);


      if (!userOwnedPages.length) {
        console.warn(`No pages owned by ${stxAddress}`);
        pagesList.innerHTML = this.getEmptyState();
        return;
      }


      // Process pages with strict contract type handling
      const processedPages = userOwnedPages.map(page => {
        const pageContractType = page.contractType;
        if (!pageContractType) {
          console.error('Page missing contract type:', page);
          throw new Error(`Page ${page.id} is missing contract type`);
        }

        return {
          ...page,
          contractType: pageContractType // Use only the contract field
        };
      });

      console.log('Processed pages:', processedPages.map(p => ({
        id: p.id,
        contractType: p.contractType,
        title: p.title
      })));

      pagesList.innerHTML = `
            <div class="dashboard-view">
                ${processedPages.map(page => this.renderPageCard(page)).join('')}
            </div>
            <div class="page-edit-panel none"></div>
        `;

      this.initializePageHandlers();

      // Initialize counters for each page
      processedPages.forEach(page => {
        this.initializeCharCounters(page);
      });

    } catch (error) {
      console.error('Error loading pages:', error);
      pagesList.innerHTML = this.getErrorState(stxAddress);
    }
  }

  renderPageCard(page) {
    const userSession = new UserSession();
    const userData = userSession.loadUserData();
    const senderAddress = userData.profile.stxAddress.mainnet;

    // Debug logging
    console.log('========= renderPageCard called =========');
    console.log('Raw page data:', page);

    // Add extensive validation and logging
    if (!page) {
      console.error('Page object is null or undefined');
      return '';
    }

    if (!page.contractType) {
      console.warn('Page has no contract type:', page);
    }

    console.log('Rendering page:', {
      contractType: page.contractType,
      id: page.id,
      title: page.title || '',
      description: page.description || '',
      active: !!page.active,
      metadata: page.metadata || { attributes: [] }
    });

    const MAX_TITLE_LENGTH = 64;
    const MAX_DESCRIPTION_LENGTH = 256;

    console.log('Metadata before processing:', page.metadata);


    // Ensure metadata structure exists
    if (!page.metadata) {
      console.log('No metadata found, initializing empty structure');
      page.metadata = { attributes: [] };
    }
    if (!page.metadata.attributes) {
      console.log('No attributes found, initializing empty array');
      page.metadata.attributes = [];
    }

    console.log('Current attributes:', page.metadata.attributes);

    const festiveMode = false; // Initial festive mode is always false until explicitly set

    // When creating safePageData, ensure we're properly copying metadata
    const safePageData = {
      id: page.id,
      title: page.title || '',
      description: page.description || '',
      active: !!page.active,
      contractType: page.contractType,
      metadata: {
        ...page.metadata,
        attributes: [...(page.metadata.attributes || [])]
      }
    };

    console.log('Safe page data:', safePageData);

    return `
      <div id="page-${page.id}" class="page-card ${festiveMode ? 'festive-mode' : ''}" data-contract-type="${page.contractType}">
        <div class="page-header">
          <div class="page-title">
            <h3>${page.title || `Page #${page.id}`}</h3>
            <p class="dashboard-page-description">${page.description || 'No description available'}</p>
            <div class="status-badge ${page.active ? 'active' : 'inactive'}">
              ${page.active ? 'Active' : 'Inactive'}
            </div>
          </div>
          <div class="page-actions">
            <a href="#/${this.sanitizeUrl(page.title)}" target="_blank" class="view-btn">View Page</a>
            <button class="settings-cog">
            <i class="fas fa-cog"></i>
            </button>
          </div>
        </div>
        
        <div class="settings-panel none">
          <div class="settings-section">
            <h4>Page Settings</h4>
            <div class="settings-group">
              <label>Title</label>
              <input type="text"
               class="page-title-input"
               value="${page.title}"
               maxlength="${MAX_TITLE_LENGTH}" />
              <div class="char-count-title">
               <span id="edit-title-counter-${page.id}">0</span>/${MAX_TITLE_LENGTH}
             </div>
            </div>
            <div class="settings-group">
              <label>Description</label>
               <textarea
               class="page-description-input"
               rows="3"
               maxlength="${MAX_DESCRIPTION_LENGTH}">${page.description || ''}</textarea>
                <div class="char-count-title">
                <span id="edit-description-counter-${page.id}">0</span>/${MAX_DESCRIPTION_LENGTH}
                </div>
            </div>
           <div class="settings-group">
      <label>🎄 Make it Festive </label>
      <div class="toggle-switch">
        <input type="checkbox"
          class="festive-toggle"
          id="festive-${page.contractType}-${page.id}" 
          data-page-id="${page.id}"
          data-contract-type="${page.contractType}"
          ${festiveMode ? 'checked' : ''} />
          <label for="festive-${page.contractType}-${page.id}"></label>
      </div>
      ${festiveMode ? `
      <div class="nft-dropdown-container" style="margin-top: 10px;">
        <select class="nft-select">
          ${currentFestiveNFT ? `
            <option value="${currentFestiveNFT.value}" selected>
              ${currentFestiveNFT.value.split('::')[1] || 'Current NFT'}
            </option>
          ` : ''}
          <option value="">Select an NFT</option>
        </select>
        ${currentFestiveNFT ? `
          <div class="nft-preview" style="margin-top: 10px;">
            <img src="${currentNFTImageUrl}" 
              alt="NFT Preview" 
              style="max-width: 100px; height: auto; border-radius: 4px;">
          </div>
        ` : ''}
      </div>
` : ''}
    </div>
          </div>
          
          <div class="settings-footer">
        <button
    class="delete-btn"
    data-page='${JSON.stringify({
      id: page.id,
      title: page.title,
      description: page.description,
      stxAddress: senderAddress,
      contractType: page.contractType
    }).replace(/'/g, "&apos;")}'>
    Unpublish Page
</button>          
        <div class="action-buttons">
              <button class="cancel-btn">Cancel</button>
                <button
                class="save-btn"
data-page='${JSON.stringify(safePageData).replace(/'/g, "&apos;")}'
                data-contract-type="${page.contractType}" 
                >
                Next
            </button>            
            </div>
          </div>
        </div>
      </div>
    `;
  }

  async attachFestiveListeners() {
    const toggles = document.querySelectorAll('.festive-toggle');

    toggles.forEach(toggle => {
      console.log('Attaching listener to toggle:', toggle.id);
      // Get initial state from page data
      const pageCard = toggle.closest('.page-card');
      const saveBtn = pageCard.querySelector('.save-btn');
      const pageData = JSON.parse(saveBtn.dataset.page);

      // Check both festive mode and NFT from metadata
      const festiveMode = pageData?.metadata?.attributes?.find(
        attr => attr.trait_type === "Festive Mode" && attr.value === "On"
      );
      const currentFestiveNFT = pageData?.metadata?.attributes?.find(
        attr => attr.trait_type === "Festive NFT"
      )?.value;

      let tokenId = null;

      if (currentFestiveNFT) {
        const [contractAddress, assetName] = currentFestiveNFT.split('::');
        tokenId = assetName; // Extract the token ID from the currentFestiveNFT
      }

      console.log('Initial state:', { festiveMode, currentFestiveNFT, tokenId });


      if (festiveMode && currentFestiveNFT) {

        const nftValue = typeof currentFestiveNFT === 'string' ?
          currentFestiveNFT :
          currentFestiveNFT.value;


        // Initialize fall effect for current NFT
        const contractAddress = nftValue.split('::')[0];
        const imageUrl = `https://assets.hiro.so/api/mainnet/token-metadata-api/${contractAddress}/1.png`;

        // Populate other NFTs immediately
        const dropdownContainer = pageCard.querySelector('.nft-dropdown-container');
        if (dropdownContainer) {
          this.populateNFTOptions(dropdownContainer, nftValue);
        }
      }

      // If festive mode is on, set the toggle and create dropdown
      if (festiveMode) {
        console.log('Setting up initial festive state');
        toggle.checked = true;

        // Create and populate dropdown immediately
        let dropdownContainer = pageCard.querySelector('.nft-dropdown-container');
        if (!dropdownContainer) {
          dropdownContainer = document.createElement('div');
          dropdownContainer.className = 'nft-dropdown-container';
          dropdownContainer.style.marginTop = '10px';

          // Add to DOM
          const settingsGroup = toggle.closest('.settings-group');
          if (settingsGroup) {
            settingsGroup.appendChild(dropdownContainer);
          }

          // Trigger the change event which will populate the dropdown
          const event = new Event('change');
          toggle.dispatchEvent(event);
        }
      }

      toggle.addEventListener('change', async (e) => {
        const isChecked = e.target.checked;

        // Log the full page data to see what we're working with
        console.log('Page data:', pageData);

        // First, get the current festive NFT from attributes
        const currentFestiveNFT = pageData?.metadata?.attributes?.find(
          attr => attr.trait_type === "Festive NFT"
        )?.value;

        console.log('Current Festive NFT from attributes:', currentFestiveNFT);

        let dropdownContainer = pageCard.querySelector('.nft-dropdown-container');

        if (isChecked) {
          console.log("ischecked", isChecked)

          if (!dropdownContainer) {
            console.log('Creating new dropdown container');
            dropdownContainer = document.createElement('div');
            dropdownContainer.className = 'nft-dropdown-container';
            dropdownContainer.style.marginTop = '10px';


            dropdownContainer.innerHTML = `
                <select class="nft-select">
                  <option value="">Loading NFTs...</option>
                </select>
      `;
            // Insert the dropdown
            const settingsGroup = e.target.closest('.settings-group');
            if (settingsGroup) {
              settingsGroup.appendChild(dropdownContainer);
            }
          } else {
            // If container exists, just show it
            dropdownContainer.style.display = 'block';
          }

          try {
            // Fetch NFTs
            const userSession = new UserSession();
            const userData = userSession.loadUserData();
            const userAddress = userData.profile.stxAddress.mainnet;

            const nftData = await this.fetchNFTs(userAddress);
            console.log('Fetched NFT data:', nftData);

            const select = dropdownContainer.querySelector('.nft-select');
            select.innerHTML = '<option value="">Select an NFT</option>';

            if (nftData && nftData.results) {
              let foundCurrentNFT = false;

              console.log('Processing NFTs with currentFestiveNFT:', currentFestiveNFT);

              // Process all NFTs except the currently selected one
              for (const nft of nftData.results) {

                const [contractAddress, assetName] = nft.asset_identifier.split('::');
                const tokenId = nft.value.repr.replace('u', '');
                const nftIdentifier = nft.asset_identifier; // Full identifier
                const NFTimageUrl = `https://assets.hiro.so/api/mainnet/token-metadata-api/${contractAddress}/${tokenId}.png`;

                console.log('Processing NFT:', {
                  identifier: nftIdentifier,
                  currentFestiveNFT,
                  tokenId,
                  isMatch: nftIdentifier === currentFestiveNFT
                });

                const option = document.createElement('option');
                option.value = nft.asset_identifier;
                option.id = tokenId,
                  option.textContent = `${assetName} #${tokenId}`; // Name followed by ID
                option.dataset.NFTimageUrl = NFTimageUrl;

                select.appendChild(option);
              }
              console.log('Found current NFT in options:', foundCurrentNFT);

              // Add change listener to show preview
              select.addEventListener('change', (e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                const NFTimageUrl = selectedOption.dataset.NFTimageUrl;
                const selectedName = selectedOption.textContent.split(' (ID: ')[0]; // Extract name
                const selectedId = selectedOption.id;

                console.log('Selected NFT Name:', selectedName);
                console.log('Selected NFT ID:', selectedId);
                // Update preview
                const previewDiv = dropdownContainer.querySelector('.nft-preview')
                  || document.createElement('div');
                previewDiv.className = 'nft-preview';
                previewDiv.style.marginTop = '10px';

                if (NFTimageUrl) {
                  previewDiv.innerHTML = `
                                        <img src="${NFTimageUrl}" 
                                            alt="NFT Preview" 
                                            style="max-width: 100px; height: auto; border-radius: 4px;">
                                    `;
                  if (!previewDiv.parentNode) {
                    dropdownContainer.appendChild(previewDiv);
                  }

                  // Update fall effect
                  if (this.currentEffect) {
                    document.body.removeChild(this.currentEffect.canvas);
                    this.currentEffect = null;
                  }
                  this.currentEffect = initNFTFallEffect(NFTimageUrl);
                }

                // Update page data
                try {
                  const pageData = JSON.parse(saveBtn.dataset.page);

                  // Update attribute
                  const nftIndex = pageData.metadata.attributes.findIndex(
                    attr => attr.trait_type === "Festive NFT"
                  );
                  if (nftIndex >= 0) {
                    pageData.metadata.attributes[nftIndex].value = nftId;
                  } else {
                    pageData.metadata.attributes.push({
                      trait_type: "Festive NFT",
                      value: nftId
                    });
                  }

                  // Update properties structure
                  if (!pageData.metadata.properties) pageData.metadata.properties = {};
                  if (!pageData.metadata.properties.meta) pageData.metadata.properties.meta = {};
                  if (!pageData.metadata.properties.meta.content) pageData.metadata.properties.meta.content = {};

                  pageData.metadata.properties.meta.content.festive = {
                    enabled: true,
                    nft: {
                      identifier: nftIdentifier,
                      name: selectedOption.textContent,
                      imageUrl: NFTimageUrl
                    }
                  };

                  saveBtn.dataset.page = JSON.stringify(pageData);
                } catch (error) {
                  console.error('Error updating page data:', error);
                }
              });

              dropdownContainer.style.display = 'block';

            }
          } catch (error) {
            console.error('Error loading NFTs:', error);
            const select = dropdownContainer.querySelector('.nft-select');
            if (select) select.innerHTML = '<option value="">Error loading NFTs</option>';
          }
        } else {
          if (dropdownContainer) {
            console.log('hiding dropdown container');
            dropdownContainer.style.display = 'none';
          }

          // Remove the effect when festive mode is turned off
          if (this.currentEffect) {
            document.body.removeChild(this.currentEffect.canvas);
            this.currentEffect = null;
          }
        }
      });
    });
  }

  updateNFTPreview(container, imageUrl) {
    const previewDiv = container.querySelector('.nft-preview')
      || document.createElement('div');
    previewDiv.className = 'nft-preview';
    previewDiv.style.marginTop = '10px';
    previewDiv.innerHTML = `
    <img src="${imageUrl}" 
      alt="NFT Preview" 
      style="max-width: 100px; height: auto; border-radius: 4px;">
  `;
    if (!previewDiv.parentNode) {
      container.appendChild(previewDiv);
    }
  }

  async fetchNFTs(walletAddress) {
    const url = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${walletAddress}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  }

  initializeCharCounters(page) {
    const titleInput = document.querySelector(`#page-${page.id} .page-title-input`);
    const descInput = document.querySelector(`#page-${page.id} .page-description-input`);
    const titleCounter = document.querySelector(`#edit-title-counter-${page.id}`);
    const descCounter = document.querySelector(`#edit-description-counter-${page.id}`);

    if (titleInput) {
      titleCounter.textContent = titleInput.value.length;
      titleInput.addEventListener('input', () => {
        titleCounter.textContent = titleInput.value.length;
      });
    }

    if (descInput) {
      descCounter.textContent = descInput.value.length;
      descInput.addEventListener('input', () => {
        descCounter.textContent = descInput.value.length;
      });
    }
  }

  async togglePageStatus(id, currentStatus) {
    const button = event.currentTarget;
    button.disabled = true;

    try {
      await this.updatePage({
        id,
        active: currentStatus,
        // Add other required fields here
      });
    } catch (error) {
      console.error('Toggle failed:', error);
      // Reset button state
      button.textContent = currentStatus ? 'Unpublish' : 'Publish';
      button.classList.toggle('active', currentStatus);
    } finally {
      button.disabled = false;
    }
  }

  async fetchNFTPages(stxAddress = null) {
    try {

      let userAddressMainnet;
      if (stxAddress) {
        userAddressMainnet = stxAddress;
      } else {
        const userData = this.auth.userSession.loadUserData();
        userAddressMainnet = userData.profile.stxAddress.mainnet;
      }

      console.log('Fetching NFTs for mainnet address:', userAddressMainnet);

      const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressMainnet}&limit=50`;

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const holdingsResponse = await fetch(nftHoldingsUrl);
      if (!holdingsResponse.ok) return { pages: [], nfts: [] };

      const holdingsData = await holdingsResponse.json();
      if (!holdingsData.results?.length) return { pages: [], nfts: [] };

      const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);
      console.log(`Found ${sortedNFTs.length} NFTs total`);

      const processedContent = [];
      const seen = new Set();

      for (const nft of sortedNFTs) {
        const [contractAddress, assetName] = nft.asset_identifier.split('::');
        console.log('Processing NFT:', { contractAddress, assetName });

        // Only process gated-page NFTs
        if (assetName === 'gated-page') {
          const tokenId = nft.value?.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;
          if (!tokenId) {
            console.log('Skipping NFT - invalid token ID');
            continue;
          }

          try {
            const options = {
              contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
              contractName: 'gated-pages',
              functionName: 'get-page-by-id',
              functionArgs: [uintCV(tokenId)],
              network: STACKS_MAINNET,
              senderAddress: userAddressMainnet
            };

            await delay(150);
            const result = await fetchCallReadOnlyFunction(options);
            if (result?.value?.value) {
              const pageData = result.value.value;

              // Initialize your page object as before
              const page = {
                type: 'page',
                id: tokenId,
                owner: pageData.owner.value,
                active: pageData.active?.value ?? true,
                title: pageData.title.value,
                description: pageData.description.value,
                url: `https://${userAddressMainnet}.gated.so/#/${pageData.title?.data || tokenId}`,
                blockHeight: nft.block_height,
                contractType: 'gated-pages'
              };

              // Now get fresh metadata using get-token-uri
              const tokenUriOptions = {
                contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
                contractName: 'gated-pages',
                functionName: 'get-token-uri',
                functionArgs: [uintCV(tokenId)],
                network: STACKS_MAINNET,
                senderAddress: userAddressMainnet
              };

              await delay(150); // Add delay to prevent rate limiting
              const tokenUriResult = await fetchCallReadOnlyFunction(tokenUriOptions);
              console.log('Token URI Result for page', tokenId, ':', tokenUriResult);

              if (tokenUriResult?.value?.value?.value) {
                const ipfsUri = tokenUriResult.value.value.value;
                const httpUrl = ipfsUri.replace('ipfs://', 'https://ipfs.io/ipfs/');

                try {
                  const metadataResponse = await fetch(httpUrl);
                  if (metadataResponse.ok) {
                    const metadata = await metadataResponse.json();
                    if (metadata?.properties?.meta?.content) {
                      const content = metadata.properties.meta.content;
                      Object.keys(content).forEach(key => {
                        if (content[key]?.content) {
                          content[key].content = content[key].content.replace(/\\n/g, '\n');
                        }
                      });
                    }
                    console.log('Fresh metadata fetched for page:', {
                      tokenId,
                      metadata
                    });
                    page.metadata = metadata;
                  } else {
                    console.error('Failed to fetch metadata from', httpUrl);
                    page.metadata = { attributes: [] };
                  }
                } catch (error) {
                  console.error('Error fetching metadata from IPFS:', error);
                  page.metadata = { attributes: [] };
                }
              } else {
                console.log('No token URI found for page', tokenId);
                page.metadata = { attributes: [] };
              }

              const key = `${page.owner}-${page.title}`;
              if (!seen.has(key)) {
                seen.add(key);
                processedContent.push(page);
              }
            }
          } catch (error) {
            console.error('Error processing page:', error);
          }
        }
      }

      this.pages = processedContent.filter(item => item && item.type === 'page');
      console.log('Final pages with metadata:', this.pages);
      return { pages: this.pages, nfts: [] };

    } catch (error) {
      console.error('Error in fetchNFTPages:', error);
      return { pages: [], nfts: [] };
    }
  }

  // Helper method to format addresses
  formatAddress(address) {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  // Add these navigation methods
  viewNFTPage(contractAddress, tokenId) {
    // Implement view page navigation
    window.open(`/${contractAddress}/${tokenId}`, '_blank');
  }

  editNFTPage(contractAddress, tokenId) {
    // Implement edit page navigation
    window.location.href = `/edit/${contractAddress}/${tokenId}`;
  }


  renderClaimPage(stxAddress) {
    console.log('Starting renderClaimPage with address:', stxAddress);

    console.log('Auth object state:', {
      authObject: this.auth,
      userSession: this.auth.userSession,
      isSignedInMethod: this.auth.isSignedIn(),
      isUserSignedInSession: this.auth.userSession.isUserSignedIn()
    });



    // Add retry logic to check auth state
    const maxAttempts = 5;
    let attempts = 0;


    console.log(`Checking auth state, attempt ${attempts + 1} of ${maxAttempts}`);

    if (this.auth.userSession.isUserSignedIn()) {
      console.log('User is signed in, proceeding with render');

    };



    // Add delay to ensure auth state is ready
    setTimeout(async () => {
      // First check if session is still valid
      if (!this.auth.userSession.isUserSignedIn() && this.auth.isSignedIn()) {
        console.log('Session expired, triggering sign out');
        this.auth.handleSignOut();
        return;
      }
      
      let isSignedIn = false;

      if (stxAddress) {
        const isSignedIn = true
        console.log("Signed in with address:", stxAddress)
      }
      const parsedUrl = this.parseUrl().stxAddress;
      if (parsedUrl) {
        console.log("Parsed URL:", parsedUrl)
      }

      // Normalize addresses to lowercase for comparison
      const normalizedPageAddress = parsedUrl?.toLowerCase();
      const normalizedCurrentAddress = stxAddress?.toLowerCase();
      const matchesPage = normalizedCurrentAddress === normalizedPageAddress;

    
      console.log('Claim page state:', {
        isSignedIn,
        stxAddress,
        normalizedCurrentAddress,
        normalizedPageAddress,
        matchesPage,
        network: window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet'
      });

      let contentToShow;

      if (!isSignedIn) {
        // Setup for unauthenticated users
        contentToShow = `
    <div class="wallet-connect-section">
      <p>To claim this page, connect your wallet:</p>
      <div class="action-buttons">
        <button id="connectWallet-button" class="primary-btn">
          Connect Wallet
        </button>
      </div>
    </div>
  `;
      } else if (matchesPage) {
        // User is signed in AND matches the page address
        console.log('User matches page, redirecting to dashboard');

        // Clear the current content to prevent flashing of claim page
        this.mainContent.innerHTML = `
    <div class="loading-container" style="display: flex; justify-content: center; align-items: center; height: 300px;">
      <div class="loading-message">
        <p>Redirecting to your dashboard...</p>
      </div>
    </div>
  `;

        // Get dashboard URL
        const dashboardUrl = this.getDashboardUrl(stxAddress);

        // Force immediate navigation
        window.location.replace(dashboardUrl);

        // Return early to prevent further rendering
        return;

      } else {
        contentToShow = `
            <div class="error-message">
                <p>❌ Your wallet is connected to a different address:</p>
                <p class="suggestion">
                    Perhaps you were looking for
                    <a href="https://${currentAddress.toLowerCase()}.gated.so">
                        ${currentAddress.toLowerCase()}.gated.so
                    </a>
                </p>
                <div class="action-buttons">
                    <button id="switch-account-btn" class="primary-btn">
                        Switch Account
                    </button>
                </div>
            </div>
        `;
      }

      window.showSuccessAndRedirect = (url) => {
        const container = document.querySelector('.access-message');
        container.innerHTML = `
      <div class="alert-check success-transition">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" width="20" height="20">
          <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p>Access granted! Redirecting to dashboard...</p>
      </div>
    `;
      }
      const styles = `
  .claim-container {
    max-width: 32rem;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .claim-card {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
    border: 1px solid hsl(240 5.9% 90%);
  }

  .card-header {
    padding: 20px;
  }

  .card-title {
    padding:20px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 0;
    color: hsl(240 3.8% 46.1%);
  }

  .card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-muted {
    color: hsl(240 3.8% 46.1%);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .address-code {
    font-family: ui-monospace, monospace;
    font-size: 0.875rem;
    background: hsl(240 4.8% 95.9%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-block;
    color: hsl(240 5.9% 10%);
  }

  @media (max-width: 640px) {
    .claim-container {
      padding: 1rem;
    }
  }
`;

      // Add the styles to the document
      if (!document.getElementById('claim-page-styles')) {
        const styleSheet = document.createElement('style');
        styleSheet.id = 'claim-page-styles';
        styleSheet.textContent = styles;
        document.head.appendChild(styleSheet);
      }
      this.mainContent.innerHTML = `
  <div class="claim-container">
    <div class="claim-card">
        <h1 class="card-title">Claim This Page</h1>
      <div class="card-content">
        <div class="address-section">
          <p class="text-muted">This page belongs to STX address:</p>
          <code class="address-code">${stxAddress}</code>
        </div>
        ${contentToShow}
      </div>
    </div>
  </div>
    <img src=${gatedLogo} alt="Gated Logo" class="logo" />
`;
      if (!isSignedIn) {
        const connectButton = document.getElementById('connectWallet-button');
        if (connectButton) {
          connectButton.addEventListener('click', () => {
            window.connectWallet()
              .catch(error => {
                console.error('Failed to connect wallet:', error);
              });
          });
        }
      } else if (matchesPage) {
        // Dashboard redirect handler
        const dashboardBtn = document.getElementById('go-to-dashboard-btn');
        if (dashboardBtn) {
          dashboardBtn.addEventListener('click', () => {
            const dashboardUrl = this.getDashboardUrl(stxAddress);
            const container = document.querySelector('.access-message');
            container.innerHTML = `
                    <div class="alert-check success-transition">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" width="20" height="20">
                            <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>Access granted! Redirecting to dashboard...</p>
                    </div>
                `;
            setTimeout(() => {
              window.location.href = dashboardUrl;
            }, 1500);
          });
        }

        // Sign out handler
        const signOutBtn = document.getElementById('sign-out-btn');
        if (signOutBtn) {
          signOutBtn.addEventListener('click', () => {
            window.disconnectWallet();
          });
        }
      } else {
        // Switch account handler
        const switchAccountBtn = document.getElementById('switch-account-btn');
        if (switchAccountBtn) {
          switchAccountBtn.addEventListener('click', () => {
            window.connectWallet(true);
          });
        }
      }


    }, 500);
  }

  hideCreatePageModal() {
    const modal = document.getElementById('create-page-modal');
    const form = document.getElementById('create-page-form');
    if (modal) {
      modal.style.display = 'none';
      if (form) {
        form.reset();
        // Reset the counter displays
        document.getElementById('title-counter').textContent = '0';
        document.getElementById('description-counter').textContent = '0';
      }
    }
  }

  showCreatePageModal() {
    const MAX_TITLE_LENGTH = 64;
    const MAX_DESCRIPTION_LENGTH = 256;

    // First, inject the modal HTML if it doesn't exist
    if (!document.getElementById('create-page-modal')) {
      const modalHTML = `
    <div id="create-page-modal" class="modal" style="display: none;">
      <div class="modal-content">
      <div class="modal-header">
        <h2>Create new page</h2>
      </div>
      <div class="modal-body">
        <div id="form-errors" class="text-red-500 mb-4"></div>
        <form id="create-page-form" class="space-y-4">
          <div>
            <div class="create-page-title">Page Title</div>
            <input 
              type="text" 
              name="title" 
              id="title"
              maxlength="${MAX_TITLE_LENGTH}"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter page title" 
              required
            >
            <div class="char-count-title"><span id="title-counter">0</span>/${MAX_TITLE_LENGTH}</div>
          </div>
          <div>
            <div class="create-page-description">Description</div>
            <textarea
              name="description" 
              id="description"
              maxlength="${MAX_DESCRIPTION_LENGTH}"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="3"
              placeholder="Enter page description"
              required
            ></textarea>
            <div class="char-count-title"><span id="description-counter">0</span>/${MAX_DESCRIPTION_LENGTH}</div>
          </div>
      </div>
        <div class="modal-footer">
          <button type="button" class="cancel-btn">Cancel</button>
          <button type="button" class="btn btn-primary confirm-btn">Next</button>
          </div>
    
        </form>
      </div>
    </div>
  `;
      document.body.insertAdjacentHTML('beforeend', modalHTML);
      // Setup one-time event listeners
      const modal = document.getElementById('create-page-modal');
      const form = document.getElementById('create-page-form');
      const cancelBtn = modal.querySelector('.cancel-btn');
      const confirmBtn = modal.querySelector('.confirm-btn');

      // Character counters
      ['title', 'description'].forEach(id => {
        const input = document.getElementById(id);
        const counter = document.getElementById(`${id}-counter`);
        input.addEventListener('input', () => {
          counter.textContent = input.value.length;
        });
      });

      // Cancel button handler
      cancelBtn.addEventListener('click', () => {
        modal.style.display = 'none';
        form.reset();
      });

      // Confirm button handler
      confirmBtn.addEventListener('click', async () => {
        const errorContainer = document.getElementById('form-errors');
        errorContainer.innerHTML = '';

        try {
          const stxAddress = this.auth.userSession.loadUserData().profile.stxAddress.mainnet;
          const title = form.querySelector('input[name="title"]').value.trim();
          const description = form.querySelector('textarea[name="description"]').value.trim();
          const slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/-+$/, '').replace(/^-+/, '');

          if (title.length > MAX_TITLE_LENGTH) {
            errorContainer.innerHTML = `Title must be ${MAX_TITLE_LENGTH} characters or less`;
            return;
          }

          if (description.length > MAX_DESCRIPTION_LENGTH) {
            errorContainer.innerHTML = `Description must be ${MAX_DESCRIPTION_LENGTH} characters or less`;
            return;
          }

          if (!title || !description) {
            errorContainer.innerHTML = 'Please fill in all fields';
            return;
          }

          if (slug.length > MAX_TITLE_LENGTH) {
            errorContainer.innerHTML = 'Title generates too long of a URL after conversion';
            return;
          }

          localStorage.setItem('pendingPage', JSON.stringify({ title, description }));
          modal.style.display = 'none';
          form.reset();
          this.showPublishConfirmation(title, description, slug, stxAddress);
        } catch (error) {
          errorContainer.innerHTML = error.message;
        }
      });

      form.addEventListener('submit', (e) => e.preventDefault());


      // Modal close handlers
      modal.addEventListener('click', (e) => {
        if (e.target === modal) {
          modal.style.display = 'none';
          form.reset();
        }
      });

      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && modal.style.display === 'flex') {
          modal.style.display = 'none';
          form.reset();
        }
      });
    }

    // Create button click handler
    const createBtn = document.querySelector('.create-btn');
    if (createBtn) {
      createBtn.onclick = () => {
        const modal = document.getElementById('create-page-modal');
        modal.style.display = 'flex';
        modal.querySelector('input[name="title"]').focus();
      };
    }
  }

  showPublishConfirmation(title, description, slug, stxAddress) {

    const confirmModal = document.createElement('div');
    confirmModal.id = 'confirm-modal';
    confirmModal.className = 'modal';

    confirmModal.innerHTML = `
  <div class="modal-content">
  <div class="modal-header">
    <h2>Confirm Page Creation</h2>
  </div>
  <div class="modal-body">
    <p>You are about to create a new page:</p>
    <p><strong>Title:</strong> ${title}</p>
    <p><strong>Description:</strong> ${description}</p>
    <p><strong>URL:</strong> ${stxAddress}.gated.so/#/${slug}</p>
    <div class="modal-status"></div>
  </div>
    <div class="modal-footer">
      <button type="button" class="cancel-btn">Cancel</button>
      <button type="button" class="confirm-btn">Mint Page</button>
    </div>
  </div>
`;
    document.body.appendChild(confirmModal);

    // Setup confirmation handlers
    const cancelBtn = confirmModal.querySelector('.cancel-btn');
    const confirmBtn = confirmModal.querySelector('.confirm-btn');
    const statusDiv = confirmModal.querySelector('.modal-status');


    const closeConfirmModal = () => {
      document.body.removeChild(confirmModal);
    };

    cancelBtn.addEventListener('click', closeConfirmModal);

    // Handle confirmation and contract call
    confirmBtn.addEventListener('click', async () => {
      try {

        const pinataApiUrl = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
        const pinataApiKey = process.env.PINATA_API_KEY;
        const pinataSecretApiKey = process.env.PINATA_SECRET_KEY;

        if (!pinataApiKey || !pinataSecretApiKey) {
          throw new Error('Missing Pinata credentials');
        }

        statusDiv.textContent = 'Uploading metadata to IPFS...';
        confirmBtn.disabled = true;

        const userSession = new UserSession();
        const userData = userSession.loadUserData();
        const senderAddress = userData.profile.stxAddress.mainnet;
        const contract = 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5.gated-pages';
        const gatedFee = 1000000;
        const imageCid = "bafkreifgvle7pvczsnxvb72j224fkxabctadqsfxl3jkrhw6gyhmrk3vua";

        const metadata = {
          sip: 16,
          name: title,
          description,
          image: `ipfs://${imageCid}`,
          attributes: [
            { trait_type: "Page Status", value: "Active" },
            { trait_type: "Festive Mode", value: "Off" }
          ],
          properties: {
            collection: "Gated Pages",
            total_supply: "1",
          },
          external_url: `https://${senderAddress.toLowerCase()}.gated.so/#/${slug}`
        };


        const ipfsResponse = await fetch(pinataApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'pinata_api_key': pinataApiKey,
            'pinata_secret_api_key': pinataSecretApiKey,
          },
          body: JSON.stringify({
            pinataMetadata: {
              name: `createPage-${senderAddress}-${slug}-gated-pages`,
            },
            pinataContent: metadata
          })
        });


        if (!ipfsResponse.ok) {
          throw new Error('Failed to upload to IPFS');
        }

        const { IpfsHash } = await ipfsResponse.json();
        const metadataUri = `ipfs://${IpfsHash}`;
        console.log('Generated metadata:', JSON.stringify(metadata, null, 2));
        console.log('Uploaded to IPFS:', metadataUri);

        statusDiv.textContent = 'Calling smart contract...';

        // Prepare contract call
        const functionArgs = [
          stringAsciiCV(title),
          stringAsciiCV(description),
          stringAsciiCV(metadataUri)
        ];


        const postConditions = [
          Pc.principal(senderAddress).willSendEq(gatedFee).ustx()
        ];

        const options = {
          network: STACKS_MAINNET,
          contractAddress: contract.split('.')[0],
          contractName: contract.split('.')[1],
          functionName: 'mint-page',
          functionArgs: functionArgs,
          postConditions: postConditions,
          onFinish: data => {
            console.log('Transaction:', data);
            localStorage.removeItem('pendingPage');
            window.location.href = `/#/${slug}?tx=${data.txId}`;
          }
        };

        // Make the contract call
        await openContractCall(options);

      } catch (error) {
        console.error('Error creating page:', error);
        alert(error.message);
      } finally {
        closeConfirmModal();
      }
    });

    // Close on click outside
    confirmModal.addEventListener('click', (e) => {
      if (e.target === confirmModal) {
        closeConfirmModal();
      }
    });
  }

  showUpdateConfirmation(id, title, description, slug, stxAddress, active, contract, socialLinksData) {
    const contentBlocks = Array.from(document.querySelectorAll('.editable-block')).map(block => ({
      type: block.dataset.type,
      content: block.querySelector('.editable').innerHTML
        .split('<br>')
        .join('\n')
        .replace(/<div>/g, '\n')
        .replace(/<\/div>/g, '')
        .trim(),
      order: Array.from(block.parentNode.children).indexOf(block)
    }));


    const contentBlocksPreview = contentBlocks.length > 0 ? `
  <div class="info-item">
    <span class="info-label">Content</span>
    <span class="info-value">
      <div class="blocks-preview">
        ${contentBlocks.map(block => `
 <div class="block-preview-item ${block.type}">
   <span class="block-type">${block.type === 'heading' ? 'Heading' : 'Paragraph'}</span>
   <span class="block-content">${block.content.split('\n').map(line => line.trim()).join('<br>')}</span>
 </div>
`).join('')}
      </div>
    </span>
  </div>
` : '';

    const darkModeKey = 'gatedDarkMode';

    console.log("X:", socialLinksData.links.x)
    console.log("Discord", socialLinksData.links.discord)
    console.log("Website", socialLinksData.links.website)

    // Add socialLinksData as a parameter with default value
    let socialLinksInfo = '';
    if (socialLinksData?.enabled) {
      const hasLinks = Object.values(socialLinksData.links).some(link => link.trim() !== '');
      if (hasLinks) {
        socialLinksInfo = `
            <div class="info-item">
                <span class="info-label">Social Links</span>
                <span class="info-value">
                    <div class="social-links-preview">
                        ${socialLinksData.links.x ? `
                            <div class="link-item">
                                <a href="${socialLinksData.links.x}" target="_blank" rel="noopener noreferrer">
                                    <i class="fa-brands fa-x-twitter"></i> ${socialLinksData.links.x}
                                </a>
                            </div>` : ''}
                        ${socialLinksData.links.discord ? `
                            <div class="link-item">
                                <a href="${socialLinksData.links.discord}" target="_blank" rel="noopener noreferrer">
                                    <i class="fa-brands fa-discord"></i> ${socialLinksData.links.discord}
                                </a>
                            </div>` : ''}
                        ${socialLinksData.links.website ? `
                            <div class="link-item">
                                <a href="${socialLinksData.links.website}" target="_blank" rel="noopener noreferrer">
                                    <i class="fa-solid fa-globe"></i> ${socialLinksData.links.website}
                                </a>
                            </div>` : ''}
                    </div>
                </span>
            </div>
        `;
      }
    }
    // Early validation of contract type
    if (!contract) {
      console.error('Contract type is required');
      throw new Error('Contract type must be specified');
    }

    // Create modal first
    const confirmModal = document.createElement('div');
    confirmModal.id = 'confirm-modal';
    confirmModal.className = 'modal';

    // Define helper functions
    const getCurrentPageData = () => {
      if (!this.currentPageCard) return null;

      const saveBtn = this.currentPageCard.querySelector('.save-btn');
      if (!saveBtn) return null;

      try {
        return JSON.parse(saveBtn.dataset.page);
      } catch (error) {
        console.error('Error parsing page data:', error);
        return null;
      }
    };

    const closeConfirmModal = () => {
      if (this.currentToggle) {
        this.currentToggle.removeEventListener('change', modalToggleHandler);
      }
      document.body.removeChild(confirmModal);
    };

    const handleConfirm = async () => {
      try {
        // Use the values shown in the modal instead of getCurrentPageData
        const updatedPageData = {
          id: id, // from showUpdateConfirmation parameters
          title: title,
          description: description,
          active: active,
          contractType: this.contractType,
          stxAddress: stxAddress,
          slug: slug,
          darkMode: document.documentElement.classList.contains('dark-theme'),
          profilePhoto: {
            enabled: document.querySelector('#profilePhotoEnabled')?.checked || false,
            nftId: document.querySelector('#profileNftSelect')?.value || null,
            imageUrl: document.querySelector('#nftPreview2 img')?.src || null
          },
          content: contentBlocks // Add this line
        };

        const isEnabled = this.currentToggle?.checked || false;

        const nftCollectionEnabled = document.getElementById('nftCollectionEnabled')?.checked;
        let nftCollectionData = null;

        if (nftCollectionEnabled) {
          const nfts = Array.from(document.querySelectorAll('.nft-preview-item')).map(item => ({
            name: item.querySelector('.nft-name-input').value,
            image: item.querySelector('img').src
          }));

          nftCollectionData = {
            enabled: true,
            name: document.getElementById('collectionName').value,
            items: nfts
          };
        }
        // Get NFT data if festive mode is enabled
        let nftData = {};
        if (isEnabled) {
          const dropdownContainer = this.currentPageCard.querySelector('.nft-dropdown-container');
          if (dropdownContainer) {
            const select = dropdownContainer.querySelector('.nft-select');
            if (select && select.selectedIndex > 0) {
              const selectedOption = select.options[select.selectedIndex];
              nftData = {
                festiveNFT: selectedOption.value,
                selectedNFTName: selectedOption.textContent,
                selectedNFTImage: selectedOption.dataset.imageUrl
              };
            }
          }
        }

        console.log('Using updated values:', {
          ...updatedPageData,
          festive: isEnabled,
          ...nftData
        });

        // First update metadata
        const metadataResult = await handlePageUpdate({
          ...updatedPageData,
          owner: this.currentStxAddress,
          festive: isEnabled,
          ...nftData,
          metadata: {
            socialLinks: socialLinksData,  // Include the social links data
            nftCollection: nftCollectionData,
            contentBlocks: contentBlocks
          }
        });

        console.log('Preparing contract call with:', {
          id: updatedPageData.id,
          title: updatedPageData.title,
          description: updatedPageData.description,
          active: updatedPageData.active,
          isEnabled: isEnabled,
          metadataUri: `ipfs://${metadataResult.ipfsHash}`
        });

        const userData = userSession.loadUserData();
        const senderAddress = userData.profile.stxAddress.mainnet;
        const gatedFee = 1000000;

        // Update function arguments to match contract expectations
        const functionArgs = [
          uintCV(updatedPageData.id),                        // id
          stringAsciiCV(updatedPageData.title),              // title
          stringAsciiCV(updatedPageData.description),        // description
          boolCV(!!updatedPageData.active),                  // active
          stringAsciiCV(`ipfs://${metadataResult.ipfsHash}`) // metadata-uri
        ];
        const postConditions = [
          Pc.principal(senderAddress).willSendEq(gatedFee).ustx()
        ];
        const options = {
          contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
          contractName: 'gated-pages',
          functionName: 'update-page',
          functionArgs,
          network: STACKS_MAINNET,
          postConditions: postConditions,
          onFinish: (data) => {
            console.log('Update successful:', {
              transactionData: data,
              metadataResult,
              sentArgs: {
                id: currentPageData.id,
                title: currentPageData.title,
                description: currentPageData.description,
                active: currentPageData.active,
                metadataUri: `ipfs://${metadataResult.ipfsHash}`
              }
            });
            this.showNotification('Page updated successfully!');
          }
        };

        await openContractCall(options);
      } catch (error) {
        console.error('Error updating page:', error);
        alert(error.message);
      } finally {
        closeConfirmModal();
      }
    };

    const updateModalContent = () => {

      const contentBlocks = Array.from(document.querySelectorAll('.editable-block')).map(block => ({
        type: block.dataset.type,
        content: block.querySelector('.editable').innerHTML
          .split('<br>')
          .join('\n')
          .replace(/<div>/g, '\n')
          .replace(/<\/div>/g, '')
          .trim(),
        order: Array.from(block.parentNode.children).indexOf(block)
      }));

      const contentBlocksPreview = contentBlocks.length > 0 ? `
  <div class="info-item">
    <span class="info-label">Content</span>
    <span class="info-value">
      <div class="blocks-preview">
        ${contentBlocks.map(block => `
          <div class="block-preview-item ${block.type}">
            <span class="block-type">${block.type === 'heading' ? 'Heading' : 'Paragraph'}</span>
            <span class="block-content">${block.content}</span>
          </div>
        `).join('')}
      </div>
    </span>
  </div>
` : '';

      document.querySelectorAll('.modal-content').forEach(modal => {
        modal.classList.toggle('dark-theme', localStorage.getItem('gatedDarkMode') === 'true');
      });

      const profileEnabled = document.querySelector('#profilePhotoEnabled')?.checked || false;
      const selectedNFT = document.querySelector('#profileNftSelect')?.value;
      const nftPreview = document.querySelector('#nftPreview2');
      const profilePhotoInfo = profileEnabled ? `
    <div class="info-item">
      <span class="info-label">Profile Photo</span>
      <span class="info-value">
        ${selectedNFT ? `
          <img src="${nftPreview?.querySelector('img')?.src || ''}" 
              alt="Selected NFT" 
              class="preview-profile-photo">
        ` : 'No NFT selected'}
      </span>
    </div>
  ` : '';

      const currentPageData = getCurrentPageData();
      const isEnabled = this.currentToggle?.checked || false;

      // Get selected NFT info
      let selectedNFTInfo = '';
      if (isEnabled) {
        const dropdownContainer = this.currentPageCard.querySelector('.nft-dropdown-container');

        if (dropdownContainer) {
          const select = dropdownContainer.querySelector('.nft-select');
          if (select && select.selectedIndex > 0) { // If an NFT is selected
            const selectedOption = select.options[select.selectedIndex];
            const nftName = selectedOption.textContent;
            const imageUrl = selectedOption.dataset.imageUrl;

            selectedNFTInfo = `
                  <div class="info-item">
                      <span class="info-label">Selected NFT</span>
                      <span class="info-value">
                          <div class="nft-preview-modal">
                              <img src="${imageUrl}" 
                                  alt="${nftName}" 
                                  style="width: 40px; height: 40px; border-radius: 4px; margin-right: 10px;">
                              ${nftName}
                          </div>
                      </span>
                  </div>
              `;
          }
        }
      }

      confirmModal.innerHTML = `
    <div class="modal-content ${localStorage.getItem('gatedDarkMode') === 'true' ? 'dark-theme' : ''}">

      <div class="modal-header">
          <h2>Review and confirm changes</h2>
      </div>
      
      <div class="modal-body">            
          <div class="info-grid">

             <div class="info-item">
                  <span class="info-label">Profile Photo</span>
                  <span class="info-value">
                  ${profileEnabled ?
          (selectedNFT ?
            `<img src="${nftPreview?.querySelector('img')?.src || ''}" 
                          alt="Selected NFT" 
                          class="preview-profile-photo">`
            : 'No NFT selected')
          : 'Profile Photo Disabled'}
                  </span>
              </div>
              
              <div class="info-item">
                  <span class="info-label">Dark Mode</span>
                  <span class="info-value">
                     <span class="status-badge ${document.documentElement.classList.contains('dark-theme') ? 'status-active' : 'status-inactive'}">
            ${document.documentElement.classList.contains('dark-theme') ? 'On' : 'Off'}
                      </span>
                  </span>
              </div>

              <div class="info-item">
                  <span class="info-label">ID</span>
                  <span class="info-value">${id}</span>
              </div>
              
              <div class="info-item">
                  <span class="info-label">Title</span>
                  <span class="info-value">${title}</span>
              </div>
              
              <div class="info-item">
                  <span class="info-label">Description</span>
                  <span class="info-value">${description}</span>
              </div>
              
              <div class="info-item">
                  <span class="info-label">URL</span>
                  <span class="info-value url-text">${stxAddress}.gated.so/#/${slug}</span>
              </div>
              
              
              <div class="info-item">
                  <span class="info-label">Contract</span>
                  <span class="info-value">${this.contractType}</span>
              </div>
              
              <div class="info-item">
                    <span class="info-label">Social Links</span>
                    <span class="info-value">
                            <div class="social-links-preview">
                          ${socialLinksData.links?.x ?
          `<div class="link-item">
                                  <a href="${socialLinksData.links.x}" target="_blank" rel="noopener noreferrer">
                                      <i class="fa-brands fa-x-twitter"></i> ${socialLinksData.links.x}
                                  </a>
                              </div>`
          : ''}
                          ${socialLinksData.links?.discord ?
          `<div class="link-item">
                                  <a href="${socialLinksData.links.discord}" target="_blank" rel="noopener noreferrer">
                                      <i class="fa-brands fa-discord"></i> ${socialLinksData.links.discord}
                                  </a>
                              </div>`
          : ''}
                          ${socialLinksData.links?.website ?
          `<div class="link-item">
                                  <a href="${socialLinksData.links.website}" target="_blank" rel="noopener noreferrer">
                                      <i class="fa-solid fa-globe"></i> ${socialLinksData.links.website}
                                  </a>
                              </div>`
          : ''}
                      </div>
                    </span>
                </div>

                <div class="info-item">
                      ${contentBlocksPreview}

                      </div>


                ${document.getElementById('nftCollectionEnabled')?.checked ? `
  <div class="info-item">
    <span class="info-label">NFT Collection</span>
    <span class="info-value">
      <div class="collection-preview">
        <p class="collection-name">${document.getElementById('collectionName').value}</p>
        <div class="nft-grid">
          ${Array.from(document.querySelectorAll('.nft-preview-item')).map(item => `
            <div class="nft-item">
              <img src="${item.querySelector('img').src}" alt="NFT">
              <span>${item.querySelector('.nft-name-input').value}</span>
            </div>
          `).join('')}
        </div>
      </div>
    </span>
  </div>
` : ''}

          <div class="info-item">
                  <span class="info-label">Festive Mode</span>
                  <span class="info-value">
                      <span class="status-badge ${isEnabled ? 'status-active' : 'status-inactive'}">
                          ${isEnabled ? 'On' : 'Off'}
                      </span>
                  </span>
              </div>
              ${isEnabled ? selectedNFTInfo : ''}
              <div class="info-item">
                  <span class="info-label">Status</span>
                  <span class="info-value">
                      <span class="status-badge ${active ? 'status-active' : 'status-inactive'}">
                          ${active ? 'Active' : 'Inactive'}
                      </span>
                  </span>
              </div>
          </div>
      </div>
      
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary cancel-btn">Cancel</button>
          <button type="button" class="btn btn-primary confirm-btn">Update Page</button>
      </div>
  </div>
`;

      setupEventListeners();
    };

    const modalToggleHandler = () => {
      console.log('Modal toggle handler triggered:', {
        contractType: this.contractType,
        toggleChecked: this.currentToggle?.checked
      });
      updateModalContent();
    };

    const setupEventListeners = () => {
      const cancelBtn = confirmModal.querySelector('.cancel-btn');
      const confirmBtn = confirmModal.querySelector('.confirm-btn');

      cancelBtn.addEventListener('click', closeConfirmModal);
      confirmBtn.addEventListener('click', handleConfirm);

    };

    // Find page card and verify contract type
    const pageCard = document.querySelector(`#page-${id}`);
    if (!pageCard) {
      throw new Error(`Page card not found for ID: ${id}`);
    }

    // Find toggle
    const toggle = pageCard.querySelector(`#festive-${contract}-${id}`);
    if (!toggle) {
      throw new Error(`Toggle not found for page ID ${id} and contract ${contract}`);
    }

    // Store references
    this.currentToggle = toggle;
    this.currentPageCard = pageCard;
    this.contractType = contract;

    // Add event listeners
    this.currentToggle.addEventListener('change', modalToggleHandler);
    confirmModal.addEventListener('click', (e) => {
      if (e.target === confirmModal) {
        closeConfirmModal();
      }
    });

    // Initialize modal
    updateModalContent();
    document.body.appendChild(confirmModal);
  }

  showDeactivateConfirmation(pageId, title, description, metadataUri, active, contract) {
    const confirmModal = document.createElement('div');
    confirmModal.id = 'confirm-modal';
    confirmModal.className = 'modal';

    // Get user's STX address from session
    const userSession = new UserSession();
    const userData = userSession.loadUserData();
    const stxAddress = userData.profile.stxAddress.mainnet;
    const senderAddress = userData.profile.stxAddress.mainnet;
    const gatedFee = 1000000;


    confirmModal.innerHTML = `
  <div class="modal-content">
      <div class="modal-header">
      <h2>Review and confirm deactivation</h2>
      </div>
      
      <div class="modal-body">
        <div class="info-grid">

        <div class="info-item">
              <div class="info-label">ID</div>
              <div class="info-value">${pageId}</div> 
          </div>         
          
          <div class="info-item">
              <div class="info-label">Title</div>
              <div class="info-value">${title}</div>
          </div>
          
          <div class="info-item">
              <div class="info-label">Description</div>
              <div class="info-value">${description}</div>
          </div>
          
          <div class="info-item">
              <div class="info-label">URL</div>
              <div class="info-value monospace">${stxAddress}.gated.so/#/${this.sanitizeUrl(title)}</div>
          </div>
          
          
        </div>
          <div class="modal-status"></div>
      </div>
      
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary cancel-btn">Cancel</button>
          <button type="button" class="create-btn confirm-btn">Unpublish Page</button>
      </div>
  </div>
  `;

    document.body.appendChild(confirmModal);

    const statusDiv = confirmModal.querySelector('.modal-status');
    const cancelBtn = confirmModal.querySelector('.cancel-btn');
    const confirmBtn = confirmModal.querySelector('.confirm-btn');

    const closeConfirmModal = () => {
      document.body.removeChild(confirmModal);
    };

    cancelBtn.addEventListener('click', closeConfirmModal);

    confirmBtn.addEventListener('click', async () => {
      try {
        statusDiv.textContent = 'Updating metadata...';
        confirmBtn.disabled = true;
        cancelBtn.disabled = true;

        // Prepare the page update data
        const updatedPageData = {
          id: pageId,
          title: title,
          description: description,
          active: false,
          owner: stxAddress,
          contractType: contract,
          stxAddress: stxAddress,
          slug: this.sanitizeUrl(title),
          pageStatus: "Inactive"
        };

        // Update metadata with inactive status
        const metadataResult = await handlePageUpdate(updatedPageData);
        console.log(metadataResult);

        statusDiv.textContent = 'Preparing contract call...';


        // Prepare contract call arguments
        const functionArgs = [
          uintCV(pageId),
          stringAsciiCV(title),
          stringAsciiCV(description),
          boolCV(false), // Set active to false
          stringAsciiCV(`ipfs://${metadataResult.ipfsHash}`)
        ];

        const postConditions = [
          Pc.principal(senderAddress).willSendEq(gatedFee).ustx()
        ];

        // Prepare contract call options
        const options = {
          network: STACKS_MAINNET,
          contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
          contractName: 'gated-pages',
          functionName: 'update-page',
          functionArgs,
          postConditions: postConditions,
          onFinish: data => {
            console.log('Transaction submitted:', data);
            this.showNotification('Page deactivation transaction submitted', 'success');
            closeConfirmModal();

            if (data.txId) {
              window.location.href = `/dashboard?tx=${data.txId}`;
            }
          },
          onCancel: () => {
            this.showNotification('Transaction cancelled', 'info');
            closeConfirmModal();
          }
        };

        statusDiv.textContent = 'Please confirm the transaction in your wallet...';
        await openContractCall(options);

      } catch (error) {
        console.error('Error deactivating page:', error);
        statusDiv.textContent = `Error: ${error.message}`;
        confirmBtn.disabled = false;
        cancelBtn.disabled = false;
        this.showNotification('Failed to deactivate page: ' + error.message, 'error');
      }
    });


  }


  // Add this method to set up deactivation event listeners
  setupDeactivationHandlers() {
    document.addEventListener('click', (e) => {
      if (e.target.matches('.delete-btn')) {
        if (this.currentEffect) {
          document.body.removeChild(this.currentEffect.canvas);
          this.currentEffect = null;
        }

        const pageData = JSON.parse(e.target.dataset.page);
        this.showDeactivateConfirmation(
          pageData.id,
          pageData.title,
          pageData.description,
          pageData.metadata?.uri || ''
        );
      }
    });
  }

  formatAddress(address) {
    if (!address) return '';
    address = address.toUpperCase();
    return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
  }

  renderAdminDashboard(stxAddress) {
    console.log('Rendering admin dashboard for:', stxAddress);

    this.mainContent.innerHTML = `
        <div class="admin-dashboard">
            <header class="dashboard-header">
              <div class="dashboard-header-text-container">
                                    <a href="https://${stxAddress}.gated.so" style="text-decoration: none;">
                    <img src="${gatedLogo}" div class="logo">
                    <div class="under-logo">Alpha 0.3</div>
                    </a>
                
                </div> 
               <div class="wallet-dropdown">
    <button id="wallet-dropdown-btn" class="class="dropdown-item"> 
      ${this.formatAddress(this.auth.getCurrentAddress())}
        <i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
  
    </button>
    <div id="wallet-dropdown-content" class="wallet-dropdown-content">
        <button onclick="window.disconnectWallet()" class="dropdown-item-dashboard">
           Logout <i class="fa-sharp-duotone fa-solid fa-arrow-right-from-bracket"></i> 
            
        </button>

    </div>
</div>
            </header>

            <div class="dashboard-content">
                <section class="sidebar">
                   <div class="dashboard-header-text">
                        Dashboard
                      </div>

                <hr style="/* border-bottom: navajowhite; */
                  background-color: #e8e8e8;
                  width: 100%;
                  height: 1px;
                  border: none;">
                  <div class="sidebar-header">
                  Menu
                  </div>
                  <div class="sidebar-list">
                    <div class="item-pages">Pages</div>
                    <div class="item-media">Media</div>
                  </div>
                </section>

                <section class="pages-section">
                    <div class="section-header">
                        <div class="section-header-text">
                          <div class="section-header-title">Your Pages</div>
                          <div class="section-header-subtitle">Pages you own. Secured by blockchain.</div>
                        </div>
                          <button class="create-btn">Create New Page</button>
                    </div>
                    <div id="pages-list" class="pages-list">
                        Loading pages from Blockchain...
                    </div>
                </section>
            </div>
        </div>
    `;

    // Add dropdown functionality after rendering


    // Initialize tab switching
    this.initializeSidebarTabs(stxAddress);

    // Initialize other dashboard functionality
    this.loadPages(stxAddress);
    this.showCreatePageModal();
    this.setupWalletDropdown();

  }


  initializeSidebarTabs(stxAddress) {
    // First verify the elements exist
    const pagesTab = this.mainContent?.querySelector('.item-pages');
    const mediaTab = this.mainContent?.querySelector('.item-media');
    const contentSection = this.mainContent?.querySelector('.pages-section');

    const addTabStyles = () => {
      if (document.getElementById('tab-styles')) return;

      const styles = `
    .item-pages, .item-media {
      cursor: pointer;
      padding: 7px 15px;
      transition: all 0.2s ease;
    }

    .item-pages:hover, .item-media:hover {
      background-color: #f3f4f6;
    }

    .item-pages.active, .item-media.active {
      background-color: #d2580b;
      font-weight: bold;
      color: white;
      border-radius:5px;
    }
  `;

      const styleSheet = document.createElement('style');
      styleSheet.id = 'tab-styles';
      styleSheet.textContent = styles;
      document.head.appendChild(styleSheet);
    };

    if (!pagesTab || !mediaTab || !contentSection) {
      console.error('Required elements not found for tab initialization');
      return;
    }

    // Initialize music player if it doesn't exist
    if (!window.musicPlayer) {
      window.musicPlayer = {
        isInitialized: false,
        currentTrackIndex: 0,
        audioPlayer: new Audio(),


        init() {
          if (this.isInitialized) return;
          this.setupAudioPlayer();
          this.isInitialized = true;
        },

        setupAudioPlayer() {
          this.audioPlayer.addEventListener('timeupdate', this.updateProgress.bind(this));
          this.audioPlayer.addEventListener('ended', this.handleTrackEnd.bind(this));
        },

        setCurrentTrack(index) {
          this.currentTrackIndex = index;
        },


        togglePlayPause() {
          if (this.audioPlayer.paused) {
            this.audioPlayer.play();
          } else {
            this.audioPlayer.pause();
          }
          this.updatePlayPauseButton();
        },

        updateProgress() {
          const progressBar = document.getElementById('progress-bar');
          const duration = document.getElementById('song-duration');

          if (progressBar && !isNaN(this.audioPlayer.duration)) {
            const percentage = (this.audioPlayer.currentTime / this.audioPlayer.duration) * 100;
            progressBar.value = percentage;
          }

          if (duration) {
            // Calculate remaining time instead of elapsed time
            const remainingTime = this.audioPlayer.duration - this.audioPlayer.currentTime;
            duration.textContent = this.formatTime(remainingTime);
          }
        },


        updatePlayerUI(song) {
          const albumArt = document.getElementById('album-art');
          const trackInfo = document.getElementById('track-info');
          const stickyPlayer = document.getElementById('music-player-sticky');

          if (albumArt) albumArt.src = song.imgUrl;
          if (trackInfo) trackInfo.textContent = `${song.songArtist} - ${song.songName}`;
          if (stickyPlayer) stickyPlayer.style.display = 'flex';
        },

        formatTime(seconds) {
          if (isNaN(seconds)) return "0:00";

          // Round down to nearest second to avoid fractional seconds
          seconds = Math.floor(seconds);

          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;

          // Format as M:SS
          return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        },
      };
    }

    // Define content display functions first
    const showPagesContent = () => {
      contentSection.innerHTML = `
        <div class="section-header">
          <div class="section-header-text">
            <div class="section-header-title">Your Pages</div>
            <div class="section-header-subtitle">Pages you own. Secured by blockchain.</div>
          </div>
          <button class="create-btn">Create New Page</button>
        </div>
        <div id="pages-list" class="pages-list">
          Loading pages from Blockchain...
        </div>
      `;

      this.loadPages(stxAddress);
      this.showCreatePageModal();
    };

    const showMediaContent = () => {
      // First ensure musicPlayer exists
      if (!window.musicPlayer) {
        console.error('Music player not initialized');
        return;
      }

      contentSection.innerHTML = `
    <div class="section-header">
      <div class="section-header-text">
        <h2>${musicData.title}</h2>
        <div class="section-header-subtitle">${musicData.subtitle}</div>
      </div>
    </div>
    <div class="media-container">
      <div class="media-grid" id="media-grid"></div>
    </div>
    <div id="music-player-sticky" class="sticky-player">
      <div class="player-content">
        <img id="album-art" src="" alt="Album Art" class="album-art">
        <div class="track-info-container">
          <div id="track-info" class="track-info"></div>
          <div class="player-controls">
            <button id="prev-btn" class="control-btn">
              <i class="fa-solid fa-backward"></i>
            </button>
            <button id="play-pause-btn" class="control-btn">
              <i class="fa-solid fa-play"></i>
            </button>
            <button id="next-btn" class="control-btn">
              <i class="fa-solid fa-forward"></i>
            </button>
            <input 
              type="range" 
              id="volume-control" 
              min="0" 
              max="100" 
              value="50" 
              class="volume-slider"
            >
          </div>
        </div>
        <div class="progress-container">
          <input type="range" id="progress-bar" min="0" max="100" value="0">
          <span id="song-duration"></span>
        </div>
      </div>
    </div>
  `;



      requestAnimationFrame(() => {
        // Initialize grid first
        this.initializeMediaGrid();

        // Initialize music player if needed
        if (!window.musicPlayer.isInitialized) {
          window.musicPlayer.init();
        }


      });
    };


    // Tab click handler factory
    const handleTabClick = (activeTab, inactiveTab, showContent) => (e) => {
      e.preventDefault();
      activeTab.classList.add('active');
      inactiveTab.classList.remove('active');
      showContent();
    };

    // Create handlers
    const handlePagesClick = handleTabClick(pagesTab, mediaTab, showPagesContent);
    const handleMediaClick = handleTabClick(mediaTab, pagesTab, showMediaContent);

    // Store current handlers on the instance to allow cleanup
    this.currentHandlers = {
      handlePagesClick,
      handleMediaClick
    };

    // Clean up any existing listeners using stored handlers
    if (this.currentHandlers) {
      pagesTab.removeEventListener('click', this.currentHandlers.handlePagesClick);
      mediaTab.removeEventListener('click', this.currentHandlers.handleMediaClick);
    }

    // Add new listeners
    pagesTab.addEventListener('click', handlePagesClick);
    mediaTab.addEventListener('click', handleMediaClick);

    // Set Pages tab as active by default
    pagesTab.classList.add('active');
    mediaTab.classList.remove('active');

    // Show initial pages content
    showPagesContent();
    addTabStyles();
    // Add styles
    this.addMediaStyles();


    // Add this helper method
    function updateStickyPlayerUI(controls) {
      const currentSong = musicData.songs[window.musicPlayer.currentTrackIndex];

      // Update album art
      if (controls.albumArt && currentSong.imgUrl) {
        controls.albumArt.src = currentSong.imgUrl;
      }

      // Update track info
      if (controls.trackInfo) {
        controls.trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
      }

      // Update play/pause button
      if (controls.playPauseBtn) {
        controls.playPauseBtn.innerHTML = window.musicPlayer.audioPlayer.paused ?
          '<i class="fa-solid fa-play"></i>' :
          '<i class="fa-solid fa-pause"></i>';
      }

      // Show sticky player
      const stickyPlayer = document.getElementById('music-player-sticky');
      if (stickyPlayer) {
        stickyPlayer.style.display = 'flex';
      }
    }

    // Add this helper method
    function updateStickyPlayerProgress(controls) {
      if (!window.musicPlayer.audioPlayer) return;

      // Update progress bar
      if (controls.progressBar) {
        const percentage = (window.musicPlayer.audioPlayer.currentTime / window.musicPlayer.MAX_PLAY_TIME) * 100;
        controls.progressBar.value = percentage;
      }

      // Update duration display
      if (controls.durationDisplay) {
        const timeLeft = Math.ceil(window.musicPlayer.MAX_PLAY_TIME - window.musicPlayer.audioPlayer.currentTime);
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        controls.durationDisplay.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
      }
    }

    // Add this helper method
    function addStickyPlayerStyles() {
      if (document.getElementById('sticky-player-styles')) return;

      const styles = `
    .sticky-player {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      padding: 1rem;
      box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
      z-index: 1000;
    }

    .player-content {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 1rem;
      align-items: center;
    }

    .album-art {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      object-fit: cover;
    }

    .track-info-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .player-controls {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .control-btn {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      transition: opacity 0.2s;
    }

    .control-btn:hover {
      opacity: 0.8;
    }

    .progress-container {
      grid-column: 1 / -1;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    #progress-bar {
      flex: 1;
    }

    #song-duration {
      min-width: 4ch;
      text-align: right;
    }
  `;

      const styleSheet = document.createElement('style');
      styleSheet.id = 'sticky-player-styles';
      styleSheet.textContent = styles;
      document.head.appendChild(styleSheet);
    };


  }
  // Add this new method to your class

  // First, let's create a mapping between NFT names and track data
  TRACK_MAPPING = {
    'radio-silence': {
      songName: "Radio Silence",
      songArtist: "Josie Field",
      imgUrl: track1Image,
      audioUrl: track1Audio
    },
    'you-make-it-alright': {
      songName: "You Make It Alright",
      songArtist: "Ard Matthews",
      imgUrl: track2Image,
      audioUrl: track2Audio
    },
    'mortals': {
      songName: "Mortals",
      songArtist: "Arno Carstens",
      imgUrl: track3Image,
      audioUrl: track3Audio
    },
    'buzz-head': {
      songName: "Buzz Head",
      songArtist: "Kahn Morbee",
      imgUrl: track4Image,
      audioUrl: track4Audio
    },
    'grey': {
      songName: "Grey",
      songArtist: "Laurie Levine",
      imgUrl: track5Image,
      audioUrl: track5Audio
    },
    'exile': {
      songName: "Exile",
      songArtist: "Jacques Moolman",
      imgUrl: track6Image,
      audioUrl: track6Audio
    },
    'i-shouldnt-break': {
      songName: "I Shouldn't Break",
      songArtist: "Evert Snyman",
      imgUrl: track7Image,
      audioUrl: track7Audio
    },
    'bbq-to-braai': {
      songName: "BBQ to Braai",
      songArtist: "Xander",
      imgUrl: track8Image,
      audioUrl: track8Audio
    }
  };
  async initializeMediaGrid() {
    const mediaGrid = document.getElementById('media-grid');
    if (!mediaGrid || !musicData) return;

    try {
      const userData = this.auth.userSession.loadUserData();
      const userAddressMainnet = userData.profile.stxAddress.mainnet;

      const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressMainnet}&limit=50`;
      const holdingsResponse = await fetch(nftHoldingsUrl);
      if (!holdingsResponse.ok) throw new Error('Failed to fetch NFT holdings');

      const holdingsData = await holdingsResponse.json();
      const ownedNFTs = new Set();

      if (holdingsData.results) {
        for (const nft of holdingsData.results) {
          const matches = nft.asset_identifier.match(/^(SP[A-Z0-9]+)\.([^:]+)::([^:]+)$/);
          if (matches &&
            matches[1] === 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5' &&
            matches[2] === 'gated-005') {
            ownedNFTs.add(matches[3]);
          }
        }
      }

      const ownedTracks = Array.from(ownedNFTs)
        .map(nftName => this.TRACK_MAPPING[nftName])
        .filter(track => track);

      if (ownedTracks.length === 0) {
        mediaGrid.innerHTML = `
                <div class="no-tracks-message">
                    <p>No owned tracks found. Purchase tracks to start listening!</p>
                </div>
            `;
        return;
      }

      const testMode = false;
      const hasCompleteCollection = testMode ? true : ownedTracks.length === 8;
      console.log(`Collection Status: ${ownedTracks.length}/8 tracks owned`);
      console.log('Complete collection:', hasCompleteCollection);

      const gridHTML = `
            ${hasCompleteCollection ? `
                <div class="media-item" data-index="album">
                    <img src="${albumImage}" alt="Complete Album" />
                    <div class="play-overlay">
                        <i class="fa-solid fa-crown fa-2x" style="color: gold;"></i>
                    </div>
                    <div class="song-info">
                        <h3>Complete Collection</h3>
                        <p>${ownedTracks.length}/8 Tracks Owned</p>
                    </div>
                </div>
            ` : ''}
            ${ownedTracks.map((song, index) => `
                <div class="media-item" data-index="${index}">
                    <img src="${song.imgUrl}" alt="${song.songName}" />
                    <div class="play-overlay">
                        <i class="fa-solid fa-play fa-2x play-icon" style="color: white;"></i>
                        <i class="fa-solid fa-pause fa-2x pause-icon" style="color: white; display: none;"></i>
                    </div>
                    <div class="song-info">
                        <h3>${song.songName}</h3>
                        <p>${song.songArtist}</p>
                    </div>
                </div>
            `).join('')}
        `;

      mediaGrid.innerHTML = gridHTML;

      if (!window.musicPlayer.isInitialized) {
        window.musicPlayer.init();
      }

      if (ownedTracks.length > 0) {
        window.musicPlayer.loadTrack(0, ownedTracks, true);
      }

      const mediaItems = mediaGrid.querySelectorAll('.media-item:not(.non-playable)');
      mediaItems.forEach(item => {
        item.addEventListener('click', () => {
          const index = parseInt(item.dataset.index);
          if (!window.musicPlayer) return;

          if (index === 0 && !window.musicPlayer.audioPlayer.src) {
            window.musicPlayer.loadTrack(0, ownedTracks, true);
          }

          const isCurrentTrack = window.musicPlayer.currentTrackIndex === index;
          const isPlaying = !window.musicPlayer.audioPlayer.paused;

          if (isCurrentTrack && isPlaying) {
            window.musicPlayer.togglePlayPause();
          } else if (isCurrentTrack && !isPlaying) {
            window.musicPlayer.togglePlayPause();
          } else {
            window.musicPlayer.loadTrack(index, ownedTracks, true);
            setTimeout(() => window.musicPlayer.togglePlayPause(), 100);
          }
        });
      });

    } catch (error) {
      console.error('Error in initializeMediaGrid:', error);
      mediaGrid.innerHTML = `
            <div class="error-message">
                <p>Error loading your music collection. Please try again later.</p>
                <p>Error: ${error.message}</p>
            </div>
        `;
    }
  }


  updateStickyPlayerUI(controls) {
    if (!window.musicPlayer?.audioPlayer) return;

    const currentSong = musicData.songs[window.musicPlayer.currentTrackIndex];

    if (controls.albumArt) {
      controls.albumArt.src = currentSong.imgUrl;
    }

    if (controls.trackInfo) {
      controls.trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
    }

    if (controls.playPauseBtn) {
      controls.playPauseBtn.innerHTML = window.musicPlayer.audioPlayer.paused ?
        '<i class="fa-solid fa-play"></i>' :
        '<i class="fa-solid fa-pause"></i>';
    }
  }

  // Update the updateStickyPlayerProgress method in your Router class
  updateStickyPlayerProgress(controls) {
    if (!window.musicPlayer?.audioPlayer?.duration) return;

    const currentTime = window.musicPlayer.audioPlayer.currentTime;
    const duration = window.musicPlayer.audioPlayer.duration;

    if (controls.progressBar) {
      controls.progressBar.value = (currentTime / duration) * 100;
    }

    if (controls.durationDisplay) {
      // Calculate remaining time
      const remainingTime = duration - currentTime;
      const minutes = Math.floor(remainingTime / 60);
      const seconds = Math.floor(remainingTime % 60);
      controls.durationDisplay.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  }

  // Separate method for styles
  addMediaStyles() {
    if (document.getElementById('media-grid-styles')) return;

    const styles = `
        .media-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
        }

        .media-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            gap: 20px;
            padding: 20px 0;
        }

        .media-item {
            position: relative;
            aspect-ratio: 1;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            transition: transform 0.2s ease, box-shadow 0.2s ease;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .media-item:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0,0,0,0.2);
        }

        .media-item img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .media-item .play-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        .media-item:hover .play-overlay {
            opacity: 1;
        }

        .media-item .song-info {
            color: white;
            text-align: center;
            padding: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7);
        }

        .media-item .song-info h3 {
            margin: 0;
            font-size: 0.9em;
            font-weight: 500;
        }

        .media-item .song-info p {
            margin: 5px 0 0;
            font-size: 0.8em;
            opacity: 0.8;
        }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.id = 'media-grid-styles';
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
  }

  // Add this new method to handle dropdown setup
  setupWalletDropdown() {
    console.log('Setting up wallet dropdown');

    const style = document.createElement('style');
    style.textContent = `
    .wallet-dropdown-content {
      display: none;
      position: absolute;
      background: white;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      z-index: 100;
      margin-top: 8px; /* Gap between button and dropdown */
      right: 0; /* Align to right side of container */
    }

    .wallet-dropdown-content.show {
      display: block;
    }

    .wallet-btn {
      display: flex;
      align-items: center;
      gap: 8px; /* Space between text and icon */
    }

    /* Add gap below dropdown button */
    .wallet-dropdown {
      position: relative;
    }
  `;
    document.head.appendChild(style);

    
    const dropdownBtn = document.getElementById('wallet-dropdown-btn');
    const dropdownContent = document.getElementById('wallet-dropdown-content');

    if (!dropdownBtn || !dropdownContent) {
      console.error('Dropdown elements not found');
      return;
    }

    // Toggle dropdown on button click
    dropdownBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      dropdownContent.classList.toggle('show');
      console.log('Dropdown toggled');
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (e) => {
      if (!dropdownBtn.contains(e.target)) {
        dropdownContent.classList.remove('show');
      }
    });

    // Prevent dropdown from closing when clicking inside content
    dropdownContent.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  }

  async loadUserPages(stxAddress) {
    try {
      const pagesList = document.getElementById('pages-list');
      const pages = await window.fetchPagesForAddress(stxAddress);

      if (pages.length === 0) {
        pagesList.innerHTML = `
      < div class="empty-state" >
          <p>You haven't created any pages yet.</p>
          <p>Click "Add New Page" to get started.</p>
        </ >
      `;
        return;
      }

      pagesList.innerHTML = pages.map(page => {
        const pageUrl = `${ window.location.protocol }//${stxAddress}.${window.location.host}/#/${page.title}`;

    return `
          <div class="page-card">
            <div class="page-header">
              <h3 class="page-title">${page.title}</h3>
              <div class="url-container flex items-center gap-2">
                <p class="page-url truncate">${pageUrl}</p>
                <button
                  onclick="copyPageUrl('${pageUrl}')" 
                  class="copy-btn flex items-center p-2 hover:bg-gray-100 rounded"
                  title="Copy URL"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="page-actions">
              <a href="${page.title}" class="action-btn btn-primary">View Page</a>
            </div>
          </div>
        `;
  }).join('');

      // Add the copyPageUrl function to window
      window.copyPageUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      const btn = event.target.closest('.copy-btn');

      // Show feedback
      const originalHTML = btn.innerHTML;
      btn.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          `;

      // Reset after 2 seconds
      setTimeout(() => {
        btn.innerHTML = originalHTML;
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

} catch (error) {
  console.error('Error loading user pages:', error);
  const pagesList = document.getElementById('pages-list');
  pagesList.innerHTML = `
        <div class="empty-state">
          <p>Error loading pages. Please try again later.</p>
        </div>
      `;
}
  }

  async renderUserPage(stxAddress, pageTitle, txId) {
  try {
    // Add animation style first, before any content rendering
    const style = document.createElement('style');
    style.textContent = `
        .profile-outer-container {
          transform-origin: center;
          transition: transform 0.1s ease;
        }
        .verification-status {
          margin-top: 1rem;
          text-align: center;
        }
        .verification-link {
          color: #1da1f2;
          text-decoration: none;
          display: inline-block;
          padding: 8px 16px;
          border-radius: 20px;
          background: rgba(29, 161, 242, 0.1);
        }
        .verification-link:hover {
          background: rgba(29, 161, 242, 0.2);
        }
      `;
    document.head.appendChild(style);

    // Create verification link element
    const verificationElement = txId ?
      `<div class="verification-status">
           <a href="https://explorer.stacks.co/txid/${txId}?chain=mainnet" 
              target="_blank" 
              class="verification-link">
             View on Blockchain ✓
           </a>
         </div>` :
      `<div class="verification-status">
           <span style="color: #666;">Page Not Yet Claimed</span>
         </div>`;

    // Owner-specific profile content
    const ownerContent = txId ? `
      <div class="profile-section">
        <div id="profileOuterContainer" class="profile-outer-container">
          <div id="profileInnerContainer" class="profile-inner-container">
            <div id="profilePhoto" class="profile-photo">
              <img src=${this.blocktuneLogo} alt="Profile Photo" class="logo" style="width:180px;" />
            </div>
            <div id="tipText" class="tip-text">Tip 1 STX</div>
          </div>
        </div>
        <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
        <div id="bnsv2NameDisplay" class="bns-name">
          <a href="https://x.com/${stxAddress}" target="_blank">@${stxAddress}</a>
        </div>
        ${verificationElement}
      </div>
      <div class="page-description">
        <i>${pageTitle}</i> description goes here. 
        You can customize this description for each page.
      </div>
    ` : `
      <div class="page-section" style="text-align: center; margin: 40px 0;">
        <h1 style="font-size: 32px; margin-bottom: 40px;">${pageTitle}</h1>
        <p style="margin-bottom: 20px">
          This page belongs to 
          <code class="address-code" style="font-family: monospace;">
            ${stxAddress}
          </code>
        </p>
        ${verificationElement}
      </div>
    `;



    this.mainContent.innerHTML = `
      <div id="main-container" class="main-container flex">
        ${ownerContent}
        <div id="store-container"></div>
      </div>
    `;

    // Initialize profile exactly like in the specific page route
    if (txId) {
      setTimeout(() => {
        try {
          if (!window.profilePhoto) {
            window.profilePhoto = new ProfilePhoto();
          }
        } catch (error) {
          console.error('Error initializing profile:', error);
        }
      }, 0);
    }

    this.initializeAudioControls();
  } catch (error) {
    console.error('Error rendering user page:', error);
    this.mainContent.innerHTML = `
      <div class="error-message">Failed to load page content. Please try again later.</div>
    `;
  }
}

initializeAudioControls() {
  const playPauseBtn = document.getElementById('play-pause-btn');
  const prevBtn = document.getElementById('prev-btn');
  const nextBtn = document.getElementById('next-btn');

  playPauseBtn?.addEventListener('click', togglePlayPause);
  prevBtn?.addEventListener('click', playPreviousTrack);
  nextBtn?.addEventListener('click', playNextTrack);
}

// Add this helper method if you don't already have it
escapeHtml(unsafe) {
  if (!unsafe) return '';
  return unsafe
    .toString()
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

  async loadPageContent(stxAddress, pageTitle) {
  try {
    const nftContent = document.getElementById('nft-content');
    const content = await window.fetchPageContent(stxAddress, pageTitle);

    nftContent.innerHTML = `
        <div class="nft-card">
          <h2>${content.nftName}</h2>
          <p>${content.description}</p>
          <button onclick="window.mintNFT('${content.id}')">
            Mint NFT (${content.price} STX)
          </button>
        </div>
      `;
  } catch (error) {
    console.error('Error loading page content:', error);
  }
}
}

// Add the copy function
async function copyPageUrl(url) {
  try {
    await navigator.clipboard.writeText(url);
    const btn = event.target.closest('.copy-btn');

    // Show feedback
    const originalHTML = btn.innerHTML;
    btn.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    `;

    // Reset after 2 seconds
    setTimeout(() => {
      btn.innerHTML = originalHTML;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy:', err);
  }
}

// Initialize router
window.router = new Router();

// Export for module usage
export default Router;
export function initializeDarkModeToggle() {
    const darkModeToggle = document.getElementById('darkModeEnabled');
    const isMochinesPage = (
        window.location.href.includes('m-o-c-h-i-n-e-s') &&
        (window.location.href.includes('sp1zcyg0d3hck2f7sy8vh9zreb0jwcbsapfns8v5z') ||
            window.location.href.includes('sp327amyaajfhdsdge6ad0htacyq4ccxjgt47m2h3'))
    );

    // If it's Mochines page, force dark mode
    if (isMochinesPage) {
        updateDarkMode(true);
        if (darkModeToggle) {
            darkModeToggle.checked = true;
            darkModeToggle.disabled = true; // Optional: prevent toggling on Mochines page
        }
        return; // Exit early for Mochines page
    }

    // Normal dark mode behavior for other pages
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    if (darkModeToggle) {
        darkModeToggle.checked = savedDarkMode;
        darkModeToggle.disabled = false;
        updateDarkMode(savedDarkMode);

        darkModeToggle.addEventListener('change', (e) => {
            const isDark = e.target.checked;
            updateDarkMode(isDark);
            localStorage.setItem('darkMode', isDark);
        });
    }
}

function updateDarkMode(isDark) {
    document.body.style.backgroundColor = isDark ? '#1a1a1a' : '';
    document.body.classList.toggle('dark-mode', isDark);

    const nameDisplay = document.getElementById('Bnsv2NameDisplay');
    if (nameDisplay) {
        nameDisplay.style.color = isDark ? '#F5F5F5' : '';
    }

    const descriptions = document.querySelectorAll('.page-description');
    descriptions.forEach(desc => {
        desc.style.color = isDark ? '#7d7d7d' : '';
    });

    // Additional dark mode styles for Mochines page
    if (window.location.href.includes('m-o-c-h-i-n-e-s')) {
        const nftGrid = document.querySelector('.nft-collection-grid');
        if (nftGrid) {
            nftGrid.style.backgroundColor = '#1a1a1a';
        }

        const nftPreviews = document.querySelectorAll('.nft-preview');
        nftPreviews.forEach(preview => {
            preview.style.border = '1px solid #333';
            preview.style.backgroundColor = '#1a1a1a';
        });

        // Add any other Mochines-specific dark mode styles here
    }
}
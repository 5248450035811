// mochineNFTContracts.js
import { openContractCall, } from '@stacks/connect';

import {
    contractPrincipalCV, principalCV, uintCV, Pc, NonFungibleConditionCode, PostConditionMode, Cl, FungibleConditionCode

} from '@stacks/transactions';

import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';

const PROVIDER = 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5';
const GATED = 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5';
const MINT_PRICE = 19000000; // 19 STX
const GATED_FEE = 1000000;   // 1 STX

export const MOCHINE_CONTRACTS = {
    ANONYMOUS: {
        address: `${GATED}.MOCHINES-ANONYMOUS_`,
        name: 'Anonymous Mochine',
        contractName: 'MOCHINES-ANONYMOUS_',
        image: 'https://ipfs.io/ipfs/bafybeiclzmscka2kp65rvddesrdjjnrr5456cxohl7jdtvt7mhhbu2unxe',
        available: false, 
        totalSupply: 1
    },
    CHAD: {
        address: `${GATED}.MOCHINES-CHAD`,
        name: 'Chad Mochine',
        contractName: 'MOCHINES-CHAD',
        image: 'https://ipfs.io/ipfs/QmYKq5ifnRjYGpn6M1jgW5tqoBFxHryfwB7nhbvoV3JiSL',
        available: false,
        totalSupply: 1
 

    },
    DEAD_INSIDE_HAROLD: {
        address: `${GATED}.MOCHINES-DEAD-INSIDE-HAROLD`,
        name: 'Dead Inside Harold',
        contractName: 'MOCHINES-DEAD-INSIDE-HAROLD',
        image: 'https://ipfs.io/ipfs/QmV2EeT38KeqmTC9oYtogefzcintXgMBhFednPtY1Une1q',
        available: false, 
        totalSupply: 1

    },
    DOGE: {
        address: `${GATED}.MOCHINES-DOGE`,
        name: 'Doge Mochine',
        contractName: 'MOCHINES-DOGE',
        image: 'https://ipfs.io/ipfs/QmdHggAttQkNasdrRt7jDxUwX1pw9LEPfNw49Y5555E9M4',
        available: false, 
        totalSupply: 1
    },
    MUNEEB: {
        address: `${GATED}.MOCHINES-MUNEEB`,
        name: 'Muneeb Mochine',
        contractName: 'MOCHINES-MUNEEB',
        image: 'https://ipfs.io/ipfs/QmRKdQTJKCqgR2JVySKqhmyeAxrmkLQ23U1khNQHV9FA4n',
        available: false, 
        totalSupply: 1
    },
    MUSK: {
        address: `${GATED}.MOCHINES-MUSK`,
        name: 'Musk Mochine',
        contractName: 'MOCHINES-MUSK',
        image: 'https://ipfs.io/ipfs/QmSVBcBmvDnkV1k1yyzEfNNNncWakGnj7gfKASdQ5mNjBp',
        available: false, 
        totalSupply: 1
    },
    PEPETOSHI: {
        address: `${GATED}.MOCHINES-PEPETOSHI`,
        name: 'Pepetoshi Mochine',
        contractName: 'MOCHINES-PEPETOSHI',
        image: 'https://ipfs.io/ipfs/QmXgMJmNJaJUWJWXJuXdagC5BZATpFEPL5B1uYoihYAinf',
        available: false, 
        totalSupply: 1
    },
    PROTOTYPE_PEPE: {
        address: `${GATED}.MOCHINES-PROTOTYPE-PEPE`,
        name: 'Prototype Pepe',
        contractName: 'MOCHINES-PROTOTYPE-PEPE',
        image: 'https://ipfs.io/ipfs/QmeEtAsqmN98DsXio3KPahyw5bSXyyTJjbgjTxNanNdrLP',
        available: false, 
        totalSupply: 1
    },
    TROLL: {
        address: `${GATED}.MOCHINES-TROLL`,
        name: 'Troll Mochine',
        contractName: 'MOCHINES-TROLL',
        image: 'https://ipfs.io/ipfs/bafybeib43udl2ips77lzrmsyu7audk3iuo4oxzd7kzkbcn3ptrrrx57nva',
        available: false, 
        totalSupply: 1
    },
    WOJAK_CRYING: {
        address: `${GATED}.MOCHINES-WOJAK-CRYING`,
        name: 'Crying Wojak',
        contractName: 'MOCHINES-WOJAK-CRYING',
        image: 'https://ipfs.io/ipfs/QmQA3AYBiCmD4jQeUST7UZpNmEuDVQnuprWDzuNLcrNjUp',
        available: false, 
        totalSupply: 1
    },
    WOJAK_MASK: {
        address: `${GATED}.MOCHINES-WOJAK-MASK`,
        name: 'Masked Wojak',
        contractName: 'MOCHINES-WOJAK-MASK',
        image: 'https://ipfs.io/ipfs/QmNws74aJJmrJV5LLvBRsJdoStSqWw4eUsceooREPJVFnp',
        available: false, 
        totalSupply: 1
    },
    WOJAK_REKT: {
        address: `${GATED}.MOCHINES-WOJAK-REKT`,
        name: 'Rekt Wojak',
        contractName: 'MOCHINES-WOJAK-REKT',
        image: 'https://ipfs.io/ipfs/QmZYwseT3xJhWZEtzmirrzDLwavEfqjVnt1Di554HWor62',
        available: false, 
        totalSupply: 1
    }
};

export class MochineNFTManager {
    constructor(authService) {
        this.contracts = MOCHINE_CONTRACTS;
        this.authService = authService;
    }

    async mintNFT(contractKey) {
        if (!this.authService.isSignedIn()) {
            await this.authService.connectWallet();
            if (!this.authService.isSignedIn()) {
                throw new Error('Authentication required to mint NFTs');
            }
        }

        const contract = this.contracts[contractKey];
        if (!contract) {
            throw new Error(`Contract not found for ${contractKey}`);
        }


        const userSession = this.authService.userSession;
        console.log('User session:', userSession);
        const userData = userSession.loadUserData();
        console.log('User data:', userData);
        console.log('User address:', userData.profile.stxAddress.mainnet);

        try {


            

            const senderAddress = userData.profile.stxAddress.mainnet;
            const fee = 20000000;

            const functionArgs = [
                principalCV(senderAddress)
            ];
            const postConditions = [
                Pc.principal(userData.profile.stxAddress.mainnet).willSendEq(fee).ustx(),
            ];

            const options = {
                contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
                contractName: contract.contractName,
                functionName: 'mint',
                functionArgs,
                postConditions: postConditions,
                network: STACKS_MAINNET,
                postConditionMode: PostConditionMode.Deny,
                onFinish: (data) => {
                    console.log('Mint transaction:', data);
                },
            };

            const response = await openContractCall(options);
            return response;
        } catch (error) {
            console.error('Minting failed:', error);
            throw error;
        }
    }

    getContractInfo(contractKey) {
        return this.contracts[contractKey];
    }

    getAllContracts() {
        return this.contracts;
    }
}

export function initNFTInteractions(authService) {
    const nftManager = new MochineNFTManager(authService);

    if (!authService) {
        console.error('AuthService is required');
        return;
    }

    const nfts = document.querySelectorAll('.nft-preview');

    // Add styles for availability indicators
    const style = document.createElement('style');
    style.textContent = `
        .nft-availability {
            margin: 10px 0;
            padding: 8px 12px;
            border-radius: 4px;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
        }
        .nft-availability.available {
            background-color: #ecfdf5;
            color: #047857;
            border: 1px solid #047857;
        }
        .nft-availability.sold-out {
            background-color: #fef2f2;
            color: #dc2626;
            border: 1px solid #dc2626;
        }
        .mint-button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #d1d5db;
        }
    `;
    document.head.appendChild(style);

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            const fullscreenNFT = document.querySelector('.nft-preview.fullscreen');
            if (fullscreenNFT) {
                fullscreenNFT.classList.remove('fullscreen');
                fullscreenNFT.querySelector('.fullscreen-content')?.remove();
            }
        }
    });

    nfts.forEach(preview => {
        preview.addEventListener('click', () => {
            const nftKey = preview.getAttribute('data-contract-key')
                .toUpperCase()
                .replaceAll(' ', '')
                .trim();
            const nftInfo = nftManager.getContractInfo(nftKey);

            if (!nftInfo) {
                console.error('No contract info found for key:', nftKey);
                return;
            }

            console.log('NFT Info:', nftInfo); // Debug log

            // Remove any existing fullscreen content
            const existingFullscreen = document.querySelector('.nft-preview.fullscreen');
            if (existingFullscreen) {
                existingFullscreen.classList.remove('fullscreen');
                existingFullscreen.querySelector('.fullscreen-content')?.remove();
            }

            // Create fullscreen container
            const container = document.createElement('div');
            container.className = 'fullscreen-content';

            const details = document.createElement('div');
            details.className = 'nft-details';

            // Check availability (using lowercase 'available')
            const isAvailable = nftInfo.available;

            const availabilityHTML = isAvailable
                ? '<div class="nft-availability available">Available for Minting</div>'
                : '<div class="nft-availability sold-out">Sold Out</div>';

            details.innerHTML = `
                <div class="nft-details-content">
                    <h2 class="nft-title">${nftInfo.name}</h2>
                    <h4 class="nft-title">By Reece Swanepeol</h4>
                    <h4 class="nft-title">1/1</h4>
                    <div class="nft-price">Price: 20 STX </div>
                    <div class="nft-supply">Total Supply: ${nftInfo.totalSupply}</div>
               
                    <button class="mint-button" data-contract="${nftKey}" ${!isAvailable ? 'disabled' : ''}>
                        ${isAvailable ? 'Mint NFT' : 'Sold Out'}
                    </button>
                </div>
            `;

            const closeBtn = document.createElement('div');
            closeBtn.className = 'close-button';
            closeBtn.innerHTML = '×';

            container.appendChild(details);
            container.appendChild(closeBtn);
            preview.appendChild(container);
            preview.classList.add('fullscreen');

            // Only add mint button listener if NFT is available
            if (isAvailable) {
                const mintButton = details.querySelector('.mint-button');
                mintButton.addEventListener('click', async (e) => {
                    e.stopPropagation();
                    try {
                        await nftManager.mintNFT(nftKey);
                    } catch (error) {
                        alert('Failed to mint NFT: ' + error.message);
                    }
                });
            }

            closeBtn.addEventListener('click', (e) => {
                e.stopPropagation();
                preview.classList.remove('fullscreen');
                container.remove();
            });
        });
    });
}